import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import * as cts from "../../constants/ui/svg";
import { createSearchParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import i18n from "locales/i18n";
import ava8 from "../../assets/images/HOST IMAGE/pic01.jpg";
import noAva from "../../assets/images/noAva.png";
import { Button, Dropdown, Input, Modal } from "antd";
import { io } from "socket.io-client";
import type { MenuProps } from "antd";
// Constants
import { useAppDispatch, useAppSelector } from "app/hooks";
import { SOCKET_SERVER, URL_UPLOAD_IMAGE } from "../../constants";
import { getToken } from "helpers/Token";
import { JWPlayer } from "components/Common/JWPlayer";

// Actions
import { createChatSuccess, fetchChats } from "slices/chatSlice";
import { getDetailEvent } from "slices/eventLiveSlice";
import { changeFollow, getFollow, getPointLiveStream } from "slices/followSlice";
import { getDetailHost } from "slices/followSlice";
import { getUserInfo, updatePointAfterSendGift } from "slices/userSlice";
import { fetchGiftNameList } from "slices/giftNameSlice";
import { onViewLive, successToast, errorToast, formatMoney } from "helpers";
import { setOnlineClients, leaveRomClient } from "slices/channelSlice";
import { getLiveStream } from "slices/liveStreamSlice";
import ScreenMobileLive from "./component/ScreenMobileLive";
import BoxChatComponent from "./component/BoxChat";
import RenderCounter from "./component/RenderCount";
import useWindowSize from "../../components/Common/useWindowSize";
import { shallowEqual } from "react-redux";

type Props = {};
type Heart = { id: number; x: number; y: number };

const LiveStreamPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const [searchparams] = useSearchParams();

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const { width, height } = useWindowSize();
  const key = searchparams.get("key");
  // const socket: any = io(`${SOCKET_SERVER}/realtime`);
  const socket: any = useMemo(() => io(`${SOCKET_SERVER}/realtime`), []);
  const token = getToken();
  const navigate = useNavigate();
  const localData: any = localStorage.getItem("links") || [];
  const userInfo = useAppSelector((state) => state.users.userInfo, shallowEqual);
  const detailEvent = useAppSelector((state) => state.eventLive.detailEvent, shallowEqual);
  const detailHost = useAppSelector((state) => state.follow.detailHost, shallowEqual);
  const listFollow = useAppSelector((state) => state.follow.listFollowInStream, shallowEqual);
  const giftsName = useAppSelector((state) => state.giftName.giftsName, shallowEqual);
  const channelsSt = useAppSelector((state) => state.channels.channels, shallowEqual);
  const roomsInfo = useAppSelector((state) => state.channels.roomsInfo, shallowEqual);
  const chats = useAppSelector((state) => state.chats.chats, shallowEqual);
  const liveStream = useAppSelector((state) => state.liveStream.liveStream, shallowEqual);
  const listRankingGift = useAppSelector((state) => state.follow.listRankingGift, shallowEqual);

  const [changeNameModal, setChangeNameModal] = useState(false);
  const [joined, setJoined] = useState(false);
  // const [arrGift, setArrGift] = useState<any>([]);
  const [editName, setEditName] = useState(false);
  const [changeName, setChangeName] = useState<any>(userInfo?.nickName || userInfo?.lastName);
  const [countView, setCountView] = useState<number>(0);
  const [isShowChat, setIsShowChat] = useState(true);
  const [openBox, setOpenBox] = useState(false);
  // const [valueChat, setValueChat] = useState<any>({});
  const [selectGift, setSelectGift] = useState({ id: "", coin: 0, image: "", push: false });
  const [clickHearts, setClickHearts] = useState<Heart[]>([]);
  let heartId = 0;

  const url = window.location.href;
  const items: MenuProps["items"] = [
    {
      label: (
        <div
          onClick={() => {
            navigator.clipboard.writeText(url);
            successToast("コピーされました");
          }}
          className="flex items-center my-[3px]"
        >
          <span>{cts.connectIcon}</span>
          <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
            {i18n.t("COPY_LINK")}{" "}
          </span>
        </div>
      ),
      key: "1",
    },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.emailIcon}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("COPY_EMAIL")}{" "}
    //       </span>
    //     </div>
    //   ),
    //   key: "2",
    // },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.lineIcon1}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("SHARE")} : LINE
    //       </span>
    //     </div>
    //   ),
    //   key: "3",
    // },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.twitterIcon1}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("SHARE")} : X (Twitter)
    //       </span>
    //     </div>
    //   ),
    //   key: "4",
    // },
    // {
    //   label: (
    //     <div className="flex items-center my-[3px]">
    //       <span>{cts.instagramIcon}</span>
    //       <span className="text-[14px] ml-[12px] notosans_medium cursor-pointer text-[#1D2939]">
    //         {i18n.t("SHARE")} : Instagram
    //       </span>
    //     </div>
    //   ),
    //   key: "5",
    // },
  ];
  const itemsEdit: MenuProps["items"] = [
    {
      label: (
        <div
          onClick={() => {
            setChangeNameModal(true);
            setEditName(true);
          }}
          className="flex items-center my-[3px]"
        >
          <span>{cts.iconEditName}</span>
          <span className="text-[14px] ml-[12px] notosans_normal cursor-pointer text-[#1D2939]">
            {i18n.t("EDIT_NAME")}{" "}
          </span>
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div onClick={() => setIsShowChat((prev) => !prev)} className="flex items-center my-[3px]">
          <span>{isShowChat ? cts.iconHidden : cts.showChat}</span>
          <span className="text-[14px] ml-[12px] notosans_normal cursor-pointer text-[#1D2939]">
            {isShowChat ? i18n.t("HIDDEN") : "表示"}{" "}
          </span>
        </div>
      ),
      key: "2",
    },
  ];
  // const arrRankGift = chats
  //   ?.filter((x: any) => x?.coins > 0)
  //   ?.reduce((acc: any, item: any) => {
  //     const existingItemIndex = acc.findIndex((i: any) => item?.sender?._id === i?.sender?._id);

  //     if (existingItemIndex !== -1) {
  //       acc[existingItemIndex] = {
  //         ...acc[existingItemIndex],
  //         coins: acc[existingItemIndex].coins + item?.coins,
  //       };
  //     } else {
  //       acc.push({ ...item, coins: item?.coins });
  //     }

  //     return acc;
  //   }, [])
  //   ?.sort((x: any, y: any) => y.coins - x.coins)
  //   ?.filter((x: any, y: any) => y <= 2);
  const arrRankGift = useMemo(() => {
    if (!chats?.length) return [];

    // Dùng Map để gộp số coins theo sender._id
    const userCoinsMap = new Map<string, { sender: any; coins: number }>();

    for (const chat of chats) {
      if (chat?.coins > 0) {
        const senderId = chat.sender?._id;
        if (!senderId) continue;

        if (userCoinsMap.has(senderId)) {
          userCoinsMap.get(senderId)!.coins += chat.coins;
        } else {
          userCoinsMap.set(senderId, { sender: chat.sender, coins: chat.coins });
        }
      }
    }

    // Dùng Array.from() thay vì spread
    return Array.from(userCoinsMap.values())
      .sort((a, b) => b.coins - a.coins)
      .slice(0, 3);
  }, [chats]);
  useEffect(() => {
    if (key && roomsInfo?.length > 0) {
      const findRoom = roomsInfo.find((i: any) => i?.room === key);
      if (findRoom) {
        setCountView(findRoom?.countClients - 1 || 0);
      }
    }
  }, [roomsInfo, key]);

  const checkLeaveRoomOld = useCallback(async () => {
    const roomOld = localStorage.getItem("room_old");

    if (roomOld !== key) {
      await socket.emit("leaveRoom", roomOld);
    }

    socket.emit("joinRoom", key);

    socket.on("joinedRoom", (data: any) => {
      localStorage.setItem("room_old", data?.room);
      dispatch(setOnlineClients(data));
    });

    socket.on("leftRoom", (data: any) => {
      dispatch(leaveRomClient({ room: data?.room, countClients: data?.countClients }));
    });

    socket.on("chatToClient", (msg: any) => {
      dispatch(createChatSuccess(msg));
    });
  }, []);

  // This could be useState, useOptimistic, or other state
  // let pending = true;

  // useEffect(() => {
  //   function beforeUnload(e: any) {
  //     alert("Your file is being uploaded!");
  //     if (!pending) return;
  //     e.preventDefault();
  //   }

  //   window.addEventListener("beforeunload", beforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", beforeUnload);
  //   };
  // }, [pending]);

  // ========================

  const sendChatMessage = useCallback(
    (valueChat: string) => {
      if (openBox && selectGift.coin) {
        console.log("value box", valueChat);
        if (Number(userInfo?.points) < Number(selectGift?.coin)) {
          errorToast("さらにポイントを購入してください");
          // setTimeout(() => {
          //   navigate("/purchase-point");
          // }, 1000);
        } else {
          localStorage.setItem(
            "getUserInfo",
            String(Number(userInfo?.points) - Number(selectGift?.coin) || 0)
          );
          dispatch(updatePointAfterSendGift(selectGift.coin));
          if (selectGift?.coin && userInfo?._id) {
            const data = {
              sender: userInfo,
              receiver: key,
              room: key,
              message: selectGift.image,
              coins: selectGift?.coin || 0,
              gift: selectGift?.id || null,
              nickName: changeName || null,
              localData: true,
            };

            if (joined) {
              socket.emit("chatToServer", data);
              if (userInfo?._id === data?.sender?._id) {
                dispatch(createChatSuccess(data));
              }
            }
            if (valueChat) {
              setTimeout(() => {
                const data = {
                  sender: userInfo,
                  receiver: key,
                  room: key,
                  message: valueChat,
                  coins: 0,
                  gift: null,
                  nickName: changeName || null,
                  localData: true,
                };
                socket.emit("chatToServer", data);
                if (userInfo?._id === data?.sender?._id) {
                  dispatch(createChatSuccess(data));
                }
              }, 500);
            }

            setSelectGift({ id: "", coin: 0, image: "", push: true });
          }
        }
      } else {
        if ((selectGift?.coin || valueChat) && userInfo?._id) {
          const data = {
            sender: userInfo,
            receiver: key,
            room: key,
            message: valueChat || selectGift.image,
            coins: selectGift?.coin || 0,
            gift: selectGift?.id || null,
            nickName: changeName || null,
            localData: true,
          };

          if (joined) {
            socket.emit("chatToServer", data);
            dispatch(createChatSuccess(data));
          }
          // setTimeout(() => {
          //   dispatch(getUserInfo({ token: token }));
          // }, 1000);
          setSelectGift({ id: "", coin: 0, image: "", push: true });
        }
      }
    },
    [openBox, userInfo?.points, selectGift?.coin, joined]
  );
  const spawnHearts = () => {
    const times = 5;
    for (let i = 0; i < times; i++) {
      setTimeout(() => {
        const id = heartId++;
        const randomX = Math.floor(Math.random() * 100 - 50); // -50 to +50 px
        const randomY = Math.floor(Math.random() * 100 + 200); // 200 to 300px

        setClickHearts((prev) => [...prev, { id, x: randomX, y: -randomY }]);

        setTimeout(() => {
          setClickHearts((prev) => prev.filter((h) => h.id !== id));
        }, 2000);
      }, i * 150); // mỗi tim cách nhau 150ms trong cùng 1 đợt
    }
  };

  const handleHeartClick = () => {
    for (let i = 0; i < 5; i++) {
      setTimeout(() => {
        spawnHearts();
      }, i * 1000); // mỗi đợt cách nhau 2s
    }

    const data = {
      sender: userInfo,
      receiver: key,
      room: key,
      message: true,
      coins: selectGift?.coin || 0,
      gift: selectGift?.id || null,
      nickName: changeName || null,
    };

    if (joined) {
      socket.emit("chatToServer", data);
    }
  };

  // const onClickHeart = useCallback(() => {
  //   if (clickHeart) {
  //     const data = {
  //       sender: userInfo,
  //       receiver: key,
  //       room: key,
  //       message: clickHeart,
  //       coins: selectGift?.coin || 0,
  //       gift: selectGift?.id || null,
  //       nickName: changeName || null,
  //     };
  //     if (joined) {
  //       socket.emit("chatToServer", data);
  //     }
  //   }
  //   // if (data) {
  //   const timeout = setTimeout(() => {
  //     setClickHeart(false);
  //   }, 800);
  //   return () => clearTimeout(timeout);
  // }, [clickHeart]);
  // useEffect(() => {
  //   onClickHeart();
  // }, [onClickHeart]);
  // ========================

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [chats?.length]);

  const __renderButtonFollow = () => {
    return (
      <Button
        onClick={() => {
          if (userInfo?._id && key) {
            dispatch(changeFollow({ streamer: key, follower: userInfo?._id }));
          } else {
            errorToast("Please login!");
          }
        }}
        className={`${
          !listFollow?.find((i: any) => i?.follower?._id.includes(userInfo?._id))?.follower?._id
            ? "bg-[#EF6820] text-white hover:!border-[#EF6820] hover:!text-white"
            : "bg-[#EAECF0] text-[#475467] hover:!text-[#475467] border-none"
        } px-[12px] hover:text-none text-[14px] notosans_medium rounded-[8px] !flex !items-center max-500px:w-[120px] max-500px:justify-center `}
      >
        {!listFollow?.find((i: any) => i?.follower?._id.includes(userInfo?._id))?.follower?._id ? (
          <span className=" !flex !items-center">
            <span className="mr-[5px]">{cts.heartIcon}</span>

            {i18n.t("FOLLOW")}
          </span>
        ) : (
          <>{i18n.t("FOLLOWED")}</>
        )}
      </Button>
    );
  };

  useEffect(() => {
    if (joined) {
      setTimeout(() => {
        dispatch(getLiveStream({ user: key as string }));
      }, 5000);
    }
  }, [joined]);
  useEffect(() => {
    dispatch(fetchGiftNameList({}));
  }, [dispatch]);
  useEffect(() => {
    if (key) {
      dispatch(getDetailHost(key as string));
      dispatch(getFollow({ streamer: key, type: "get_all" }));
    }
  }, [key]);

  // useEffect(() => {
  //   setArrGift(giftsName);
  // }, [giftsName]);

  // useEffect(() => {
  //   if (token) {
  //     dispatch(getUserInfo({ token: token }));
  //   }
  // }, [token]);

  useEffect(() => {
    if (key && !joined) {
      setJoined(true);
      checkLeaveRoomOld();
    }

    return () => {
      // BAD: this will remove all listeners for the 'join' event, which may
      // include the ones registered in another component
      socket.off("joinedRoom");
      socket.off("chatToClient");
      socket.off("joinRoom");
    };
  }, [checkLeaveRoomOld]);

  useEffect(() => {
    if (key) {
      if (localData.length !== 0) {
        const arr = JSON.parse(localData);
        const nickName = arr.find((x: any) => x?.id === key)?.nickNameNew;
        if (nickName) {
          setChangeName(nickName);
          setChangeNameModal(false);
        } else {
          setChangeNameModal(true);
        }
      }
    }
  }, [localData.length]);
  useEffect(() => {
    if (key) {
      dispatch(fetchChats({ receiver: key }));
    }
  }, [key]);
  return (
    <div className="flex justify-center w-full overflow-hidden">
      {/* <RenderCounter componentName="LiveStream" /> */}
      <div className={`${width > 800 && " pt-[12px] pb-[50px]"} size_content_hd w-full !px-[0px]`}>
        <Link
          to="/"
          className={`${
            width <= 800 && "hidden"
          } flex items-center border-none hover:!border-none shadow-none hover:!shadow-none mb-[12px]`}
        >
          <span>{cts.backHome}</span>
          <span className="text-[#475467] text-[14px] notosans_medium ml-[8px]">
            {i18n.t("HOME")}
          </span>
        </Link>
        <div className="max-800px:hidden grid grid-cols-3 gap-4 h-[85vh] max-1080px:grid-cols-2 max-1080px:justify-center max-1080px:max-w-[680px] max-1080px:items-center w-full">
          <div className="flex flex-col overflow-hidden h-[85vh] max-1080px:hidden">
            <div className="bg-white rounded-[12px] flex flex-col p-[16px]">
              <div className="flex items-start  border-b border-[#EAECF0]">
                <div
                  onClick={() => {
                    navigate(
                      {
                        pathname: "/streamer-profile",
                        search: createSearchParams({
                          key: `${detailHost?._id}`,
                        }).toString(),
                      },
                      {
                        state: {
                          prevPath: "ライブストリーム",
                        },
                      }
                    );
                  }}
                  className="relative flex items-end justify-center mr-[16px]"
                >
                  <span className="absolute bottom-[-5px] icon_live_active">{cts.liveMini}</span>
                  <div className=" rounded-lg h-[50px] w-[50px] cursor-pointer">
                    <img
                      className="rounded-lg h-[50px] w-[50px] object-cover"
                      src={
                        detailHost?.avatar
                          ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}`
                          : noAva
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#101828] text-[16px] notosans_bold text_2_line">
                    {detailHost?.nickName}
                  </span>
                  <div className="flex mt-[8px] justify-between items-center pb-[16px]">
                    <div className="bg-[#EAECF0] rounded-[4px] flex items-center justify-center h-[26px] w-[76px]">
                      <span>{cts.view1Icon}</span>
                      <span className="text-[14px] notosans_normal ml-[5px]">{countView}</span>
                    </div>
                    <div className="flex items-center ">
                      <Dropdown menu={{ items }} trigger={["click"]}>
                        <Button className="border-none shadow-none">
                          <span className=" cursor-pointer">{cts.shareIcon}</span>
                        </Button>
                      </Dropdown>

                      {/* <Button className="border-none shadow-none">
                        <span className="cursor-pointer">{cts.warningIcon}</span>
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[12px]">
                <span className="text-[#101828] text-[16px] notosans_bold mt-[16px]">
                  {detailHost?.nickName}
                </span>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span>{cts.sub1Icon}</span>
                    <span className="text-[#475467] text-[14px] notosans_bold mx-[5px]">
                      {listFollow?.length || 0}
                    </span>
                    <span className="text-[#475467] text-[14px] notosans_normal">
                      {i18n.t("FOLLOWER")}
                    </span>
                  </div>
                  {__renderButtonFollow()}
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-[12px] h-[85vh] justify-center w-full">
            {key && liveStream.camera === "enable" && (
              <JWPlayer
                thumbnail={ava8}
                url={`https://live.host-live.net:1935/live/general/${key}/playlist.m3u8`}
                className="vertical-player"
              />
            )}
            {key && liveStream.camera === "disable" && (
              <img
                className="h-full w-full object-cover rounded-[12px]"
                src={
                  detailHost.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}` : noAva
                }
              />
            )}
          </div>

          <div className={`${width <= 1080 && "flex flex-col h-[85vh]"}`}>
            {width <= 1080 && (
              <div className="bg-white rounded-[12px] flex flex-col p-[16px]">
                <div className="flex items-start  border-b border-[#EAECF0]">
                  <div className="relative flex items-end justify-center mr-[16px]">
                    <span className="absolute bottom-[-5px] icon_live_active">{cts.liveMini}</span>
                    <div className=" rounded-[50%] h-[50px] w-[50px]">
                      <img
                        className="rounded-lg h-[50px] w-[50px] object-cover"
                        src={
                          detailHost.avatar
                            ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}`
                            : noAva
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-[#101828] text-[16px] notosans_bold text_2_line">
                      {detailHost?.nickName}
                    </span>
                    <div className="flex mt-[8px] justify-between items-center pb-[16px]">
                      <div className="bg-[#EAECF0] rounded-[4px] flex items-center justify-center h-[26px] w-[76px]">
                        <span>{cts.view1Icon}</span>
                        <span className="text-[14px] notosans_normal ml-[5px]">{countView}</span>
                      </div>
                      <div className="flex items-center ">
                        <Dropdown menu={{ items }} trigger={["click"]}>
                          <Button className="border-none shadow-none">
                            <span className=" cursor-pointer">{cts.shareIcon}</span>
                          </Button>
                        </Dropdown>
                        {/* <Button className="border-none shadow-none">
                          <span className="cursor-pointer">{cts.warningIcon}</span>
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-[12px]">
                  <span className="text-[#101828] text-[16px] notosans_bold mt-[16px]">
                    {detailHost?.nickName}
                  </span>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <span>{cts.sub1Icon}</span>
                      <span className="text-[#475467] text-[14px] notosans_bold mx-[5px]">
                        {listFollow?.length || 0}
                      </span>
                      <span className="text-[#475467] text-[14px] notosans_normal">
                        {i18n.t("FOLLOWER")}
                      </span>
                    </div>
                    {__renderButtonFollow()}
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col rounded-[12px] justify-between h-[85vh] max-1080px:h-full overflow-auto">
              <div>
                <div className="bg-[#FAFAFA] border-b rounded-t-[12px] border-[#D0D5DD] py-[12px] px-[16px] flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="text-[#26272B] text-[16px] notosans_bold">
                      {i18n.t("CMT")}
                    </span>
                    {/* <Button
                      onClick={() => {
                        setChangeNameModal(true);
                        setEditName(true);
                      }}
                      className="flex items-center justify-center border-none hove:!border-none hover:!text-[#EF6820] shadow-none"
                    >
                      {cts.editIcon}
                      <span className="text-[#EF6820] text-[12px] notosans_normal ml-[5px]">
                        {i18n.t("EDIT_NAME")}
                      </span>
                    </Button> */}
                    {userInfo?._id && (
                      <span className="text-[14px] text-[#26272B] notosans_normal ml-[8px]">
                        (名前: {changeName ? changeName : userInfo?.nickName})
                      </span>
                    )}
                  </div>
                  {userInfo?._id && (
                    <Dropdown menu={{ items: itemsEdit }} trigger={["click"]}>
                      <Button className="border-none shadow-none p-0">
                        <span className=" cursor-pointer">{cts.iconSettings}</span>
                      </Button>
                    </Dropdown>
                  )}
                </div>
                {arrRankGift.length > 0 && (
                  <div
                    className={`${
                      isShowChat === false && "hidden"
                    } py-[8px] px-[8px] flex items-center border-b border-[#D0D5DD] bg-white h-[64px]`}
                  >
                    {arrRankGift
                      ?.filter((x: any, y: any) => y === 0)
                      ?.map((it: any, idx: any) => {
                        return (
                          <div key={idx} className="flex flex-1 items-center pr-[20px] ">
                            <span className="icon_top1">{cts.top1}</span>
                            <img
                              className="h-[40px] w-[40px] rounded-[50%] mx-[8px] object-cover min-w-[40px]"
                              src={
                                it?.sender?.avatar
                                  ? `${URL_UPLOAD_IMAGE}/avatars/${it?.sender?.avatar}`
                                  : it?.sender?.provider_pic
                                  ? it?.sender?.provider_pic
                                  : noAva
                              }
                            />
                            <div className="flex flex-col">
                              <span className="text-[#182230] text-[16px] text_1_line notosans_bold max-1590px:text-[14px]">
                                {it?.nickName ||
                                  it?.sender?.nickName ||
                                  it?.sender?.lastName ||
                                  it?.sender?.userName}
                              </span>
                              <span className="flex items-center text-[#475467] mt-[8px] max-1590px:text-[14px] text-[16px] notosans_normal">
                                {formatMoney(Number(it?.coins))} {cts.coinIcon1}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                    <div
                      className={`${
                        arrRankGift?.length <= 1 && "hidden"
                      } bg-[#D0D5DD] w-px h-[18px]`}
                    ></div>
                    <div className="flex flex-1 items-center flex-col pl-[20px]">
                      {arrRankGift
                        ?.filter((x: any, y: any) => y > 0 && y <= 2)
                        ?.map((it: any, idx: any) => {
                          return (
                            <div key={idx} className="flex items-center justify-between w-full">
                              <div className="flex items-center">
                                <span>{idx === 0 ? cts.top2 : cts.top3}</span>
                                <img
                                  className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                                  src={
                                    it?.sender?.avatar
                                      ? `${URL_UPLOAD_IMAGE}/avatars/${it?.sender?.avatar}`
                                      : noAva
                                  }
                                />
                                <span className="text-[#182230] text_1_line text-[16px] max-1590px:text-[14px] notosans_bold">
                                  {it?.nickName || it?.sender?.nickName}
                                </span>
                              </div>
                              <span className="flex items-center text-[#475467] text-[16px] max-1590px:text-[14px] notosans_normal">
                                {formatMoney(Number(it?.coins))} {cts.coinIcon1}
                              </span>
                            </div>
                          );
                        })}
                      {/* <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <span>{cts.top2}</span>
                        <img
                          className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                          src={ava1}
                        />
                        <span className="text-[#182230] text_1_line text-[16px] max-1590px:text-[14px] notosans_bold">
                          アサヒ
                        </span>
                      </div>
                      <span className="flex items-center text-[#475467] text-[16px] max-1590px:text-[14px] notosans_normal">
                        60 {cts.coinIcon1}
                      </span>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <span>{cts.top3}</span>
                        <img
                          className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                          src={ava2}
                        />
                        <span className="text-[#182230] text_1_line text-[16px] notosans_bold max-1590px:text-[14px]">
                          櫻井絢都
                        </span>
                      </div>
                      <span className="flex items-center max-1590px:text-[14px] text-[#475467] text-[16px] notosans_normal">
                        10 {cts.coinIcon1}
                      </span>
                    </div> */}
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="relative pt-[8px] bg-white h-full overflow-hidden"> */}
              <BoxChatComponent
                sendChatMessage={sendChatMessage}
                messagesEndRef={messagesEndRef}
                joined={joined}
                isShowChat={isShowChat}
                arrGift={giftsName}
                width={width}
                changeName={changeName}
                liveStream={liveStream}
                openBox={openBox}
                setOpenBox={setOpenBox}
                // valueChat={valueChat}
                // setValueChat={setValueChat}
                selectGift={selectGift}
                setSelectGift={setSelectGift}
                clickHeart={clickHearts}
                setClickHeart={setClickHearts}
                handleHeartClick={handleHeartClick}
                detailHost={detailHost}
                chats={chats}
                userInfo={userInfo}
              />

              {/* <BoxChatComponent/> */}
            </div>
          </div>
        </div>
        {width <= 800 && (
          <>
            <ScreenMobileLive
              handleHeartClick={handleHeartClick}
              messagesEndRef={messagesEndRef}
              joined={joined}
              isShowChat={isShowChat}
              arrGift={giftsName}
              width={width}
              changeName={changeName}
              liveStream={liveStream}
              openBox={openBox}
              setOpenBox={setOpenBox}
              // valueChat={valueChat}
              // setValueChat={setValueChat}
              selectGift={selectGift}
              setSelectGift={setSelectGift}
              clickHeart={clickHearts}
              setClickHeart={setClickHearts}
              sendChatMessage={sendChatMessage}
            />
          </>
        )}
      </div>
      {userInfo?._id && (
        <Modal
          centered
          className="modal_change_name"
          open={changeNameModal}
          onOk={() => setChangeNameModal(false)}
          onCancel={() => setChangeNameModal(false)}
          title={i18n.t("CHANGE_NAME")}
          footer={() => {
            return (
              <Button
                onClick={() => {
                  setChangeNameModal(false);
                  onViewLive(key, changeName);
                }}
                className="h-[40px] w-full bg-[#EF6820] text-white flex items-center justify-center hover:border-none hover:!text-white hover:bg-[#EF6820]"
              >
                {i18n.t("CONFIRM")}
              </Button>
            );
          }}
        >
          <div className="flex flex-col">
            <span className="text-[14px] text-[#1D2939] notosans_medium">
              {editName ? i18n.t("WOULD_U_LIKE_TO_CHANGE") : i18n.t("THIS_NAME_USED")}
            </span>
            <span className="mt-[20px] text-[14px] text-[#1D2939] notosans_medium">
              {i18n.t("NICK_NAME")}
            </span>
            <Input
              value={changeName}
              onChange={(e) => {
                setChangeName(e.target.value);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LiveStreamPage;
