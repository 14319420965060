const IS_DEV = process.env.NODE_ENV !== "development" ? false : false; // true : local, false: production

export const API_URL_BASE = IS_DEV
  ? "http://192.168.10.132:3000/api"
  : // "http://127.0.0.1:3000/api"
    "https://api.host-live.net/api";

export const SOCKET_SERVER = IS_DEV ? "http://192.168.10.131:3000" : "https://api.host-live.net";
export const URL_UPLOAD_IMAGE = IS_DEV
  ? "http://192.168.10.131:3000/uploads"
  : "https://api.host-live.net/uploads";
export const ENCODE_SECRET_KEY =
  process.env?.ENCODE_SECRET_KEY || "99af1167a567418fa036f0b425a6aefa";
export const ISSERVER = typeof window === "undefined";
