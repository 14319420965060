import Footer from "../../components/Common/Footer";
import Header from "../../components/Common/Header";
import Sidebar from "../../components/Common/Sidebar";

import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Button, Form, type FormProps, Input } from "antd";

// Components
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getToken } from "helpers/Token";
import { getUserInfo } from "slices/userSlice";
import SocketPage from "../../socket";

export default function PrivateRouters() {
  const token = getToken();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const [hideSidebar, setHideSidebar] = useState(false);
  // const [valueForm, setValueForm] = useState<any>({ name: "", pass: "" });
  // const pathName = window.location.pathname;
  // type FieldType = {
  //   username?: string;
  //   password?: string;
  // };

  // const onFinish: FormProps<FieldType>["onFinish"] = (values: any) => {
  //   setValueForm({ name: values.username, pass: values.password });
  //   localStorage.setItem("username", values.username);
  //   localStorage.setItem("pass", values.password);
  // };

  // const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo({ token: token }));
    }
  }, [dispatch, navigator, token]);

  // useEffect(() => {
  //   const username = localStorage.getItem("username");
  //   const pass = localStorage.getItem("pass");

  //   if (username && pass) {
  //     setValueForm({ name: username, pass: pass });
  //   } else {
  //     setValueForm({ name: "", pass: "" });
  //   }
  // }, []);

  return (
    <>
      <SocketPage />
      <div className="wrapper ">
        <Header setHideSidebar={setHideSidebar} />
        <div className="content flex-1 bg-[#F2F4F7]">
          <Outlet />
        </div>
        <Sidebar setHideSidebar={setHideSidebar} hideSidebar={hideSidebar} />

        {/* <div className="">
    <div className="setup-page flex">
      <div className="left-side mt-[2px] overflow-auto h-[100vh] shadow-[0_3px_5px_-2px_rgba(0,0,0,0.3)]">
        <Sidebar setHideSidebar={setHideSidebar} hideSidebar={hideSidebar} />
      </div>
      <div className={`right-side overflow-auto h-screen`}>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  </div> */}
        <Footer />
      </div>
      {/* {valueForm.name === "admin" && valueForm.pass === "1" ? (
        <div className="wrapper ">
          <Header setHideSidebar={setHideSidebar} />
          <div className="content flex-1 bg-[#F2F4F7]">
            <Outlet />
          </div>
          <Sidebar setHideSidebar={setHideSidebar} hideSidebar={hideSidebar} />

          
          <Footer />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-[60px]">
          <Form
            className="w-[40%] max-500px:w-[90%] shadow-[0px_1px_3px_0px_#1018281A] bg-white flex flex-col items-center justify-center rounded-[8px] p-[20px]"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Username"
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="!mb-[0px]">
              <Button
                className="bg-[#EF6820] hover:bg-[#EF6820]/60 "
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )} */}
    </>
  );
}
