import "../../assets/scss/view/_confirm_modal.scss";
interface ConfirmModalProps {
  title: string;
  question: string;
  textBtn1: string;
  textBtn2: string;
  onClose: () => void;
  onNext: () => void;
}

function ConfirmModal({ title, question, textBtn1, textBtn2, onClose, onNext }: ConfirmModalProps) {
  return (
    <div className="confirm-modal">
      <div className="icon">
        <span>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.5 1.25C18.433 1.25 23.25 6.067 23.25 12C23.25 17.933 18.433 22.75 12.5 22.75C6.567 22.75 1.75 17.933 1.75 12C1.75 6.067 6.567 1.25 12.5 1.25ZM11.25 7.625L11.75 13.625C11.75 14.039 12.086 14.375 12.5 14.375C12.914 14.375 13.25 14.039 13.25 13.625L13.75 7.625C13.75 7.211 13.5 6.375 12.5 6.375C11.5 6.375 11.25 7.211 11.25 7.625ZM12.5 15.625C11.948 15.625 11.5 16.073 11.5 16.625C11.5 17.177 11.948 17.625 12.5 17.625C13.052 17.625 13.5 17.177 13.5 16.625C13.5 16.073 13.052 15.625 12.5 15.625Z"
              fill="#F04438"
            />
          </svg>
        </span>
      </div>
      <div className="title">{title}</div>
      <div className="question">{question}</div>
      <div className="btns">
        <button className="outline-primary" onClick={onClose}>
          {textBtn1}
        </button>
        <button
          className="outline-primary"
          onClick={() => {
            onNext();
            onClose();
          }}
        >
          {textBtn2}
        </button>
      </div>
    </div>
  );
}

export default ConfirmModal;
