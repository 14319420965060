import { useAppSelector } from "app/hooks";
import ButtonFollowComponent from "components/Common/ButtonFollow";
import { handleImageError } from "helpers";
import i18n from "locales/i18n";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { getUserOfShop } from "slices/followSlice";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { URL_UPLOAD_IMAGE } from "../../../constants";
import * as cts from "../../../constants/ui/svg";
import { shallowEqual } from "react-redux";

const PickupVideo = (props: any) => {
  const { width, height, channelsSt } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listHost = useAppSelector((state) => state.follow.listHost, shallowEqual);
  // const channelsSt = useAppSelector((state) => state.channels.channels);
  const arrLink = [
    { id: 1, link: "/pickup", icon: cts.pickIcon, name: i18n.t("PICK_UP") },
    { id: 2, link: "/delivery", icon: cts.deliverIcon, name: i18n.t("DURING_DELIVERY") },
    { id: 3, link: "/", icon: cts.regionIcon, name: i18n.t("REGION"), hide: true },
    { id: 4, link: "/ ", icon: cts.rankingIcon, name: i18n.t("RANKING"), hide: true },
    { id: 5, link: "/list-store", icon: cts.storeIcon, name: i18n.t("STORE_INFO") },
  ];

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex flex-col size_content_hd w-full">
        <div className="max-500px:grid-cols-2 max-500px:py-[24px] max-500px:gap-2 py-[50px] grid grid-cols-5 gap-4 nav_link_home max-1040px:grid-cols-3">
          {arrLink?.map((x, y) => {
            return (
              <Link
                onClick={() => window.scrollTo(0, 0)}
                key={y}
                to={x.link}
                className={`${
                  x.hide ? "border-[#D0D5DD] " : "border-[#EF6820] hover:bg-[#FEF6EE]"
                } border cursor-pointer max-500px:!h-[44px] h-[64px] w-full rounded-[12px] flex items-center justify-between max-1370px:px-[12px] px-[16px]`}
              >
                <div className="flex flex-col">
                  <span className="text-[#101828] text-[20px] text_1_line max-1370px:text-[18px] max-500px:text-[14px] notosans_bold">
                    {x.name}
                  </span>
                  {x.hide && (
                    <span className="text-[#98A2B3] text-[12px] notosans_normal">
                      (Coming Soon)
                    </span>
                  )}
                </div>
                <span className="size_icon_28">{x.icon}</span>
              </Link>
            );
          })}
        </div>
        <div className="flex justify-between showMore_pickup">
          <div className="relative flex items-center flex-1">
            <div className="h-[36px] w-[36px] max-500px:h-[28px] max-500px:w-[28px] rounded-full bg-[#F7B27A]"></div>
            <span className="text-[#101828] max-500px:text-[16px] text-[20px] notosans_bold absolute max-500px:left-[7px] left-[16px]">
              {i18n.t("PICK_UP")}
            </span>
          </div>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/pickup"
            className="flex items-center cursor-pointer"
          >
            <span className="text-[14px] text-[#101828] max-500px:text-[#475467] max-500px:text-[13px] notosans_medium mr-[5px] w-full">
              {i18n.t("SHOW_MORE")}
            </span>
            <span>{cts.right1Icon}</span>
          </Link>
        </div>
        {/* <div className="flex items-center justify-center notosans_thin">{i18n.t("NO_DATA")}</div> */}
        <Swiper
          slidesPerView={
            width >= 1400 ? 7 : width >= 1060 ? 6 : width >= 800 ? 4 : width >= 500 ? 3 : 2
          }
          spaceBetween={width > 500 ? -40 : 20}
          loop={false}
          navigation={width <= 500 ? false : true}
          modules={[Pagination, Navigation]}
          className=" w-full mt-[20px] max-500px:mt-[12px] swiper_nomination swiper1"
        >
          {listHost
            // ?.filter((i: any) => i?.enableLive !== false)
            ?.filter((it, idx) => idx <= 11)
            ?.map((x, y) => {
              return (
                <SwiperSlide
                  key={y}
                  className="flex flex-col cursor-pointer items-center justify-center"
                >
                  <div
                    onClick={() => {
                      window.scrollTo(0, 0);
                      if (channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id) {
                        navigate({
                          pathname: "/livestream",
                          search: createSearchParams({
                            key: `${x?._id}`,
                          }).toString(),
                        });
                      } else {
                        navigate({
                          pathname: "/streamer-profile",
                          search: createSearchParams({
                            key: `${x?._id}`,
                          }).toString(),
                        });
                      }
                    }}
                    className={`${
                      channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id &&
                      "border-[4px] max-500px:border-[2px] border-[#F04438] relative flex items-end justify-center"
                    } w-[120px] h-[120px] max-500px:w-[90px] max-500px:h-[90px] rounded-xl`}
                  >
                    {channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id && (
                      <span className="icon_live_profile absolute">{cts.live1Icon}</span>
                    )}
                    <img
                      className="w-[120px] h-[120px] max-500px:w-[90px] max-500px:h-[90px] rounded-lg object-cover"
                      src={`${URL_UPLOAD_IMAGE}/avatars/${x?.avatar}`}
                      onError={handleImageError}
                      alt=""
                    />
                  </div>
                  <span
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate({
                        pathname: "/streamer-profile",
                        search: createSearchParams({
                          key: `${x?._id}`,
                        }).toString(),
                      });
                    }}
                    className="text-[#0C111D] text_1_line text-[16px] max-500px:text-[14px] notosans_normal mt-[8px]"
                  >
                    {x?.nickName}
                  </span>
                  <span className="text-[#667085] text-[12px] mb-[8px] notosans_normal mt-[4px]">
                    {x?.follower?.length || 0} {i18n.t("FOLLOWER")}
                  </span>
                  <ButtonFollowComponent idStreamer={x._id} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};
export default React.memo(PickupVideo);
