import type { CheckboxProps, MenuProps } from "antd";
import { Button, Checkbox, Dropdown, Empty, Form, Input, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getToken } from "helpers/Token";
import Cookies from "js-cookie";
import i18n from "locales/i18n";
import { useGoogleLogin } from "@react-oauth/google";

import React, { useCallback, useEffect, useState } from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import liveIcon from "../../assets/images/liveIcon.png";
import liveIconRes from "../../assets/images/logoRes.png";
import axios from "axios";
import noAva from "../../assets/images/noAva.png";
import * as cts from "../../constants/ui/svg";
import {
  getUserInfo,
  login,
  registerOtpUser,
  registerUser,
  resetPasswordByEmail,
  sendOtpActiveUser,
} from "../../slices/userSlice";
import { URL_UPLOAD_IMAGE } from "../../constants";
import { debounce, errorToast, formatMoney, generateAvatar } from "helpers";
import OTPInput from "./OTPInput";
import useWindowSize from "../../components/Common/useWindowSize";
import { getUserOfShop } from "slices/followSlice";
import { getListShop } from "slices/shopSlice";

type FieldType = {
  username?: string;
  email?: string;
  password?: string;
  password_sign_up: string;
};
interface Props {
  setHideSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}
const HeaderComponent: React.FC<Props> = ({ setHideSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector((state) => state.users.userInfo);

  const pointCurren: any = localStorage.getItem("getUserInfo") || userInfo?.points;

  const register = useAppSelector((state) => state.users.register);
  const showLoading = useAppSelector((state) => state.general.loading);
  const listHost = useAppSelector((state) => state.follow.listHost);
  const listShop = useAppSelector((state) => state.shop.listShop);
  const channelsSt = useAppSelector((state) => state.channels.channels);

  const [openSearch, setOpenSearch] = useState(false);
  const [shopAndHostFiltered, setShopAndHostFiltered] = useState<any>([]);
  const [openLogin, setOpenLogin] = useState(false);
  const [openResendOTP, setOpenResendOTP] = useState(false);
  const [openSendOTP, setOpenSendOTP] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openConfirmSuccess, setOpenConfirmSuccess] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [token, setToken] = useState<any>("");
  const [rememberMe, setRememberMe] = useState(false);
  const pathName = window.location.pathname;
  const cookies_open_login = Cookies.get("open_login");
  const [timeDown, setTimeDown] = useState({ min: 3, sec: 0 });
  const [emailRegister, setEmailRegister] = useState("");
  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
  });
  const { width, height } = useWindowSize();
  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // User Image
      const userGoogle: any = await new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.open("GET", `https://www.googleapis.com/oauth2/v3/userinfo`);
        xhr.setRequestHeader("Authorization", `Bearer ${tokenResponse?.access_token}`);
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) resolve(JSON.parse(this.responseText));
          else resolve({ error: "404" });
        };
        xhr.send();
      });

      if (userGoogle?.email) {
        dispatch(
          login({
            email: userGoogle?.email,
            rememberMe,
            provider: "google",
            firstName: userGoogle?.given_name,
            lastName: userGoogle?.family_name,
            // nickName: userGoogle?.given_name + userGoogle?.family_name,
            providerId: userGoogle?.sub,
            provider_pic: userGoogle?.picture,
          })
        );
      }

      // const res = await fetch(`${API_URL_BASE}/auth/login`, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     // token: userGoogle.accessToken,
      //     provider: "google",
      //     name: userGoogle?.name,
      //     email: userGoogle?.email,
      //     provider_id: userGoogle?.sub,
      //     provider_pic: userGoogle?.picture,
      //   }),

      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });
      // data = await res.json();
    },
  });
  const loginLine = () => {
    const clientId = "2007102624";
    const redirectUri = encodeURIComponent("https://host-live.net/callback");
    const state = encodeURIComponent(Math.random().toString(36).substring(7)); // For security, use a unique state
    const scope = "profile%20openid%20email";

    const loginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&bot_prompt=aggressive`;

    window.location.href = loginUrl;
  };

  // console.log("vao header");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (!code) {
      return;
    }
    const getToken = async () => {
      try {
        const response = await axios.post(
          "https://api.line.me/oauth2/v2.1/token",
          new URLSearchParams({
            grant_type: "authorization_code",
            code: code,
            redirect_uri: "https://host-live.net/callback",
            client_id: "2007102624",
            client_secret: "c33ff757052f14b9859c511ba0ec973f",
            nonce: "random_nonce",
          }),
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        );
        // localStorage.setItem("line_token", response.data.access_token);
        // localStorage.setItem("id_token", response.data.id_token);
        navigate("/");
        // const idToken = localStorage.getItem("id_token");
        if (!response.data.id_token) {
          console.error("No ID token found. Please log in.");
          return;
        }
        const res = await axios.post(
          "https://api.line.me/oauth2/v2.1/verify",
          new URLSearchParams({
            id_token: response.data.id_token,
            client_id: "2007102624",
          })
        );
        if (res.data && response.data.id_token) {
          dispatch(
            login({
              email: res.data.email,
              rememberMe,
              provider: "google",
              firstName: response.data.name,
              nickName: res.data.name,
              providerId: res.data?.sub,
              provider_pic: res.data?.picture,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };
    getToken();
  }, [navigate]);

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="flex items-center">
          <img
            className="rounded-[20px] h-[40px] w-[40px]"
            alt="avatar"
            src={
              userInfo?.avatar
                ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}`
                : userInfo?.provider_pic
                ? userInfo.provider_pic
                : noAva
            }
          />
          <div className="flex flex-col ml-[12px] text_1_line">
            <span className="text-[14px] text-[#344054] notosans_bold cursor-pointer text_1_line">
              {userInfo?.nickName}
            </span>
            <span className="text-[14px] text-[#475467] notosans_normal cursor-pointer">
              {userInfo?.email}
            </span>
          </div>
        </div>
      ),
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: (
        <Link onClick={() => window.scrollTo(0, 0)} to="/my-account" className="flex items-center">
          <span>{cts.userAva}</span>
          <span className="text-[14px] notosans_normal cursor-pointer text-[#101828] ">
            {i18n.t("MY_ACCOUNT")}{" "}
          </span>
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <div className="flex items-center ">
          <span>{cts.headIcon}</span>
          <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
            {i18n.t("HELP")}{" "}
          </span>
        </div>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          className="flex items-center"
          onClick={() => {
            Cookies.remove("token_webnomi");
            window.location.href = "/";
          }}
        >
          <span>{cts.logOutIcon}</span>
          <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
            {i18n.t("LOG_OUT")}{" "}
          </span>
        </div>
      ),
      key: "5",
    },
  ];
  const onChangeCheckbox: CheckboxProps["onChange"] = (e) => {
    setRememberMe(e.target.checked);
  };
  const onFinishResend = (values: any) => {
    if (values?.email) {
      setEmailRegister(values?.email);
      dispatch(sendOtpActiveUser({ data: { email: values?.email } }));
    }
  };
  const onFinishFailedResend = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinishRegister = (values: any) => {
    dispatch(
      registerUser({
        userName: values?.username,
        nickName: values?.username,
        email: values?.email,
        password: values?.password,
        role: "user",
      })
    );
  };
  const onFinishFailedRegister = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinishLogin = (values: any) => {
    if (values?.email && values?.password) {
      dispatch(
        login({
          email: values?.email,
          password: values?.password,
          rememberMe: rememberMe,
          // role: null,
        })
      );
    }
  };
  const onSearchDebounceChange = useCallback(
    debounce((value: string) => {
      onSearchChange(value);
    }, 500),
    []
  );
  const onSearchChange = (value: string) => {
    setValueSearch(value);
  };
  const onFinishFailedLogin = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onFinishResendPass = (values: any) => {
    if (values.email) {
      setEmailRegister(values?.email);
      dispatch(resetPasswordByEmail({ data: { email: values?.email } }));
    }
  };

  const onFinishResendPassFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const __renderSignUpLogin = () => {
    return (
      <>
        <div
          onClick={() => setOpenSignUp(true)}
          className="cursor-pointer mr-[8px] h-[40px] max-500px:px-[0px] max-500px:h-[30px] max-500px:bg-inherit bg-[#F2F4F7] flex items-center rounded-[8px] px-[16px] max-500px:mr-[0px] max-500px:ml-[8px] text-[13px] max-500px:text-[12px] notosans_medium text-[#101828]"
        >
          {i18n.t("SIGN_UP")}
        </div>
        {width <= 500 && <div className="mx-[8px] h-[14px] w-px bg-[rgba(0,0,0,0.4)]"></div>}
        <div
          onClick={() => setOpenLogin(true)}
          className="cursor-pointer h-[40px] bg-[#EF6820] max-500px:text-[#101828] max-500px:px-[0px] max-500px:h-[30px] max-500px:bg-inherit flex items-center rounded-[8px] px-[16px] text-[13px] max-500px:text-[12px] notosans_medium text-[#FFFFFF]"
        >
          {i18n.t("LOGIN")}
        </div>
      </>
    );
  };
  const __renderModalLogin = () => {
    return (
      <Modal
        centered
        className="modal_sign_up"
        open={openLogin}
        onOk={() => setOpenLogin(false)}
        onCancel={() => setOpenLogin(false)}
      >
        <img src={liveIcon} />
        <span className="text-[24px] max-500px:text-[20px] notosans_bold mt-[20px]">
          Welcome to Host Live
        </span>
        <span className="mt-[8px] text-[14px] notosans_normal">{i18n.t("W_U_BECOME")}</span>
        <Form
          name="login"
          className="w-full mt-[20px]"
          onFinish={onFinishLogin}
          onFinishFailed={onFinishFailedLogin}
          autoComplete="off"
        >
          <span className="text-[14px] notosans_bold">{i18n.t("EMAIL_ONLY")}</span>
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              type="email"
              className="h-[40px] rounded-[12px]"
              placeholder="例: user@mail.com"
            />
          </Form.Item>
          <span className="text-[14px] notosans_bold">{i18n.t("PASSWORD")}</span>
          <Form.Item<FieldType>
            name="password"
            className="hide_pass_sign"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password className="h-[40px] rounded-[12px]" placeholder={i18n.t("PASSWORD")} />
            {/* <span
                onClick={() => {
                  setForgotPass(true);
                  setOpenLogin(false);
                }}
                className="text-[12px] cursor-pointer text-[#667085] notosans_medium flex justify-end"
              >
                {i18n.t("FORGOT")}
              </span> */}
          </Form.Item>
          <div className="flex items-center justify-between">
            <Checkbox onChange={onChangeCheckbox}>{i18n.t("REMEMBER_ME1")}</Checkbox>
            <span
              onClick={() => {
                setForgotPass(true);
                setOpenLogin(false);
              }}
              className="text-[12px] cursor-pointer text-[#667085] notosans_medium flex justify-end"
            >
              {i18n.t("FORGOT")}
            </span>
          </div>
          <Form.Item className="w-full">
            <Button
              className="bg-[#EF6820] mt-[20px] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
              htmlType="submit"
              // onClick={onLogin}
            >
              {i18n.t("LOGIN")}
            </Button>
          </Form.Item>
        </Form>
        <div className=" flex items-center w-full">
          <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
          <span className="text-[#475467] mx-[20px] w-[22%] text-[14px] notosans_bold">
            {i18n.t("OR")}
          </span>

          <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
        </div>
        <button
          onClick={() => loginGoogle()}
          className="mt-[30px] w-full  cursor-pointer h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]"
          style={{ border: "1px solid #D0D5DD" }}
        >
          <span className="mr-[12px] text-[#344054] text-[16px] notosans_bold">{cts.ggleIcon}</span>
          <span className=" text-[#344054] text-[16px] notosans_bold">
            Google{i18n.t("REGISTER_W_ACC")}
          </span>
        </button>
        <button
          onClick={loginLine}
          style={{ border: "1px solid #D0D5DD" }}
          className="mt-[12px] w-full cursor-pointer h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]"
        >
          <span className="mr-[12px] text-[#344054] text-[16px] !notosans_bold">
            {cts.lineIcon}
          </span>
          <span className=" text-[#344054] text-[16px] notosans_bold">
            LINE{i18n.t("REGISTER_W_ACC")}
          </span>
        </button>
        {/* <div className="mt-[12px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.twitterIcon}</span>Twitter{i18n.t("REGISTER_W_ACC")}
          </div>
          <span className="mt-[20px] flex items-center text-[12px] notosans_normal">
            {i18n.t("DONT_HAVE_ACC")}
            <span
              onClick={() => {
                setOpenSignUp(true);
                setOpenLogin(false);
              }}
              className="text-[12px] notosans_bold ml-[8px] cursor-pointer"
            >
              {i18n.t("REGISTRATION")}
            </span>
          </span> */}
      </Modal>
    );
  };
  const __renderModalSignUp = () => {
    return (
      <Modal
        centered
        className="modal_sign_up"
        open={openSignUp}
        onOk={() => setOpenSignUp(false)}
        onCancel={() => setOpenSignUp(false)}
      >
        <img src={liveIcon} />
        <span className="text-[24px] notosans_bold mt-[20px]">Welcome to Host Live</span>
        <span className="mt-[8px] text-[14px] notosans_normal">{i18n.t("W_U_BECOME")}</span>
        <Form
          name="basic"
          className="w-full mt-[20px]"
          onFinish={onFinishRegister}
          onFinishFailed={onFinishFailedRegister}
          autoComplete="off"
        >
          {/* <span className="text-[14px] notosans_bold flex items-center">
              {i18n.t("USER_NAME")}{" "}
            </span>
            <Form.Item<FieldType>
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input className="h-[40px] rounded-[12px]" placeholder={i18n.t("USER_NAME")} />
            </Form.Item> */}
          <span className="text-[14px] notosans_bold flex items-center">
            {i18n.t("NICK_NAME")}{" "}
            <span className="notosans_normal ml-[5px]">({i18n.t("CAN_BE_EDITED_LATER")})</span>
          </span>
          <Form.Item<FieldType>
            name="username"
            rules={[{ required: true, message: "Please input your nickname!" }]}
          >
            <Input className="h-[40px] rounded-[12px]" placeholder={i18n.t("NICK_NAME")} />
          </Form.Item>
          <span className="text-[14px] notosans_bold">{i18n.t("EMAIL_ONLY")}</span>
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              type="email"
              className="h-[40px] rounded-[12px]"
              placeholder="例: user@mail.com"
            />
          </Form.Item>
          <span className="text-[14px] notosans_bold">{i18n.t("PASSWORD")}</span>
          <Form.Item<FieldType>
            name="password"
            className="hide_pass_sign"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password className="h-[40px] rounded-[12px]" placeholder={i18n.t("PASSWORD")} />
          </Form.Item>

          <Form.Item className="w-full">
            <Button
              className="bg-[rgb(239,104,32)] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
              htmlType="submit"
            >
              {i18n.t("CREATE_NEW_ACC")}
            </Button>
          </Form.Item>
        </Form>
        <div className=" flex items-center w-full">
          <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
          <span className="text-[#475467] mx-[20px] w-[22%] text-[14px] notosans_bold">
            {i18n.t("OR")}
          </span>

          <div className="bg-[#D0D5DD] h-[1px] w-full"></div>
        </div>
        <div className="mt-[30px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
          <span className="mr-[12px]">{cts.ggleIcon}</span>Google{i18n.t("REGISTER_W_ACC")}
        </div>
        <div className="mt-[12px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
          <span className="mr-[12px]">{cts.lineIcon}</span>Line{i18n.t("REGISTER_W_ACC")}
        </div>
        <div className="bg-[#D0D5DD] h-[1px] w-full my-[20px]"></div>
        <Button
          onClick={() => {
            setOpenSignUp(false);
            setOpenResendOTP(true);
          }}
          className="!border-none hover:!text-[#EF6820] !shadow-none !p-0 hover:!border-none text-[#EF6820] text-[14px] notosans_medium"
        >
          {i18n.t("RESEND_CONFIRM_CODE")}
        </Button>

        {/* <div className="mt-[12px] w-full text-[#344054] cursor-pointer text-[16px] notosans_bold h-[44px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
            <span className="mr-[12px]">{cts.twitterIcon}</span>Twitter{i18n.t("REGISTER_W_ACC")}
          </div> */}
        {/* <span className="mt-[20px] flex items-center text-[12px] notosans_normal">
            {i18n.t("ALREADY_A_USER")}
            <span
              onClick={() => {
                setOpenLogin(true);
                setOpenSignUp(false);
              }}
              className="text-[12px] notosans_bold ml-[8px] cursor-pointer"
            >
              {i18n.t("LOGIN")}
            </span>
          </span> */}
      </Modal>
    );
  };
  const __renderModalResendOTP = () => {
    return (
      <Modal
        centered
        className="modal_sign_up"
        open={openResendOTP}
        onOk={() => setOpenResendOTP(false)}
        onCancel={() => setOpenResendOTP(false)}
      >
        <img src={liveIcon} />
        <span className="text-[24px] max-500px:text-[20px] notosans_bold mt-[20px]">
          {i18n.t("VERIFY_YOUR_ACCOUNT")}
        </span>
        <span className="mt-[8px] text-[14px] notosans_normal">
          {i18n.t("PLEASE_ENTER_UR_REGISTER_EMAIL")}
        </span>
        <Form
          name="login"
          className="w-full mt-[20px]"
          onFinish={onFinishResend}
          onFinishFailed={onFinishFailedResend}
          autoComplete="off"
        >
          <span className="text-[14px] notosans_bold">MAIL</span>
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              type="email"
              className="h-[40px] rounded-[12px]"
              placeholder="例: user@mail.com"
            />
          </Form.Item>
          <Form.Item className="w-full">
            <Button
              className="bg-[#EF6820] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
              htmlType="submit"
            >
              {i18n.t("SEND_V2")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const __renderModalSendOTP = () => {
    return (
      <Modal
        centered
        className="modal_sign_up"
        open={openSendOTP}
        onOk={() => setOpenSendOTP(false)}
        onCancel={() => setOpenSendOTP(false)}
      >
        <img src={liveIcon} />
        <span className="text-[24px] max-500px:text-[20px] notosans_bold mt-[20px]">
          {i18n.t("VERIFY_YOUR_ACCOUNT")}
        </span>
        <span className="mt-[8px] text-[14px] notosans_normal">
          {i18n.t("PLS_ENTER_VERIFICATION")}
        </span>
        <div className="bg-[#F2F4F7] rounded-[8px] mt-[16px] flex items-center justify-center h-[32px] px-[10px]">
          <span className="text-[#1D2939] text-[16px] notosans_bold">
            {(timeDown.min.toString().length === 1 ? "0" + timeDown.min : timeDown.min) +
              " : " +
              (timeDown.sec.toString().length === 1 ? "0" + timeDown.sec : timeDown.sec)}
          </span>
        </div>

        <div id="OTPInputGroup" className="digitGroup mt-[22px] w-full" data-autosubmit="true">
          <OTPInput
            id="input1"
            value={inputValues.input1}
            onValueChange={handleInputChange}
            previousId={null}
            handleSubmit={handleSubmit}
            nextId="input2"
          />
          <OTPInput
            id="input2"
            value={inputValues.input2}
            onValueChange={handleInputChange}
            previousId="input1"
            handleSubmit={handleSubmit}
            nextId="input3"
          />
          <OTPInput
            id="input3"
            value={inputValues.input3}
            onValueChange={handleInputChange}
            previousId="input2"
            handleSubmit={handleSubmit}
            nextId="input4"
          />
          <OTPInput
            id="input4"
            value={inputValues.input4}
            onValueChange={handleInputChange}
            previousId="input3"
            handleSubmit={handleSubmit}
            nextId="input1"
          />
        </div>
        <span className="text-[16px] notosans_normal mb-[16px]">
          コードが届かない場合、
          <span
            onClick={() => {
              setOpenSendOTP(false);
              setOpenResendOTP(true);
            }}
            className="text-[16px] text-[#EF6820] notosans_bold cursor-pointer"
          >
            再送信
          </span>
          をクリックしてください。
        </span>
        <Button
          disabled={
            inputValues.input1 === "" ||
            inputValues.input2 === "" ||
            inputValues.input3 === "" ||
            inputValues.input4 === ""
              ? true
              : false
          }
          onClick={() => {
            if (
              inputValues.input1 !== "" &&
              inputValues.input2 !== "" &&
              inputValues.input3 !== "" &&
              inputValues.input4 !== "" &&
              emailRegister !== ""
            ) {
              dispatch(
                registerOtpUser({
                  data: {
                    email: emailRegister,
                    otp: (
                      inputValues.input1 +
                      inputValues.input2 +
                      inputValues.input3 +
                      inputValues.input4
                    ).toString(),
                  },
                })
              );
            } else {
              errorToast(
                "まずアカウントを登録してから、登録したメールアドレスに送信されたパスコードを入力してください"
              );
            }
          }}
          className="bg-[#EF6820] mt-[16px ] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
        >
          {i18n.t("SEND_V2")}
        </Button>
      </Modal>
    );
  };
  const __renderModalForgotPassword = () => {
    return (
      <Modal
        onOk={() => setForgotPass(false)}
        onCancel={() => setForgotPass(false)}
        centered
        className="modal_sign_up"
        open={forgotPass}
      >
        <span className="text-[24px] notosans_bold text-[#111927]">{i18n.t("RESET_PASS")}</span>
        <span className="mt-[20px] text-[14px] notosans_normal">{i18n.t("WE_WILL")}</span>
        <span className="text-[14px] notosans_normal">{i18n.t("WE_WILL1")}</span>
        <Form
          name="basic"
          className="w-full mt-[20px]"
          onFinish={onFinishResendPass}
          onFinishFailed={onFinishResendPassFailed}
          autoComplete="off"
        >
          <span className="text-[14px] notosans_bold">{i18n.t("EMAIL_ONLY")}</span>
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input className="h-[40px] rounded-[12px]" placeholder="例: user@mail.com" />
          </Form.Item>
          <Form.Item className="w-full">
            <Button
              className="bg-[#EF6820] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
              htmlType="submit"
            >
              {i18n.t("SEND")}
            </Button>
          </Form.Item>
        </Form>

        <span
          onClick={() => {
            setOpenLogin(true);
            setForgotPass(false);
          }}
          className="mt-[20px] flex items-center text-[12px] notosans_normal"
        >
          {cts.returnIcon}
          <span className="text-[14px] text-[#475467] notosans_bold ml-[8px] cursor-pointer">
            {i18n.t("RETURN_LOGIN")}
          </span>
        </span>
      </Modal>
    );
  };
  const __renderModalResetPassword = () => {
    return (
      <Modal
        onOk={() => setResetPass(false)}
        onCancel={() => setResetPass(false)}
        centered
        className="modal_sign_up"
        open={resetPass}
      >
        <span className="text-[24px] notosans_bold text-[#111927]">{i18n.t("RESET_PASS")}</span>
        <span className="mt-[20px] text-[15px] text-[#101828] notosans_bold ">
          {emailRegister}
          <span className="notosans_normal">{i18n.t("RESET_UR_PASS_TO")}</span>
        </span>
        <span className="mt-[20px] text-[15px] text-[#475467] notosans_normal">
          {i18n.t("IF_U_DONT_RECEIVE")}
          <span className="notosans_bold text-[#EF6820]">{i18n.t("CLICK_TO_SEND")}</span>
        </span>
        <span
          onClick={() => {
            setOpenLogin(true);
            setEmailRegister("");
            setResetPass(false);
          }}
          className="mt-[20px] flex items-center text-[12px] notosans_normal"
        >
          {cts.returnIcon}
          <span className="text-[14px] text-[#475467] notosans_bold ml-[8px] cursor-pointer">
            {i18n.t("RETURN_LOGIN")}
          </span>
        </span>
      </Modal>
    );
  };

  const arrNew = () => {
    // this function filters the list of shops and hosts based on the search value
    const listShopWithType = listShop.map((item: any) => ({
      ...item,
      type: "shop",
    }));
    const listHostWithType = listHost.map((item: any) => ({
      ...item,
      type: "streamer",
    }));
    const combineShopAndHost = listShopWithType.concat(listHostWithType as any);
    return combineShopAndHost.filter(
      (x: any) =>
        x.nickName?.toLowerCase().includes(valueSearch.toLowerCase()) ||
        x.name?.toLowerCase().includes(valueSearch.toLowerCase()) ||
        x.userName?.toLowerCase().includes(valueSearch.toLowerCase())
    );
  };
  //this function updates the value of the state inputValues
  const handleInputChange = (inputId: any, value: any) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value > 9 ? 0 : value < 0 ? value * -1 : value,
    }));
  };

  const handleNavigate = (item: any) => {
    const isLive = channelsSt?.find((i: any) => i?.user?._id.includes(item?._id));
    console.log("is Live: ", isLive);
    if (isLive) {
      navigate({
        pathname: "/livestream",
        search: createSearchParams({
          key: `${item._id}`,
        }).toString(),
      });
    } else {
      if (item?.type === "shop") {
        navigate({
          pathname: "/detail-store",
          search: createSearchParams({
            key_store: `${item._id}`,
          }).toString(),
        });
      } else if (item?.type === "streamer") {
        navigate({
          pathname: "/streamer-profile",
          search: createSearchParams({
            key: `${item._id}`,
          }).toString(),
        });
      }
    }
    setValueSearch("");
  };

  //this function processes form submission
  const handleSubmit = () => {
    // ... Your submit logic here
  };
  useEffect(() => {
    if (openSendOTP) {
      setTimeout(() => {
        setTimeDown((prev) => ({
          ...prev,
          sec: timeDown.sec - 1,
        }));
        if (timeDown.sec <= 0) {
          setTimeDown({ min: timeDown.min - 1, sec: 59 });
        }
        if (timeDown.sec === 59) {
          setTimeDown((pr) => ({
            ...pr,
            sec: timeDown.sec - 1,
          }));
        }
        if (timeDown.min === 0 && timeDown.sec === 0) {
          setOpenSendOTP(false);
          setOpenResendOTP(true);
        }
      }, 1000);
      if (timeDown.min === 0 && timeDown.sec === 0) {
        setOpenSendOTP(false);
        setOpenResendOTP(true);
        setTimeDown({ min: 3, sec: 0 });
      }
    } else {
      setTimeDown({ min: 3, sec: 0 });
      setEmailRegister("");
    }
  }, [timeDown.min, timeDown.sec, openSendOTP]);

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo({ token: token }));
    }
  }, [token]);

  useEffect(() => {
    switch (showLoading) {
      case "register success":
        setEmailRegister(register?.email);
        setOpenSignUp(false);
        setOpenSendOTP(true);
        break;
      case "send otp success":
        setOpenSendOTP(false);
        setOpenConfirmSuccess(true);
        break;
      case "Resend otp success":
        setOpenResendOTP(false);
        setOpenSendOTP(true);
        break;
      case "Reset password success":
        setForgotPass(false);
        setResetPass(true);
        break;
      case "Resend otp failed":
        setOpenResendOTP(false);
        setOpenSignUp(true);
        break;
      default:
        return;
      // code block
    }
    // if (showLoading === "register success") {
    //   setOpenSignUp(false);
    //   setOpenSendOTP(true);
    // }
    // if (showLoading === "send otp success") {
    //   setOpenSendOTP(false);
    //   setOpenConfirmSuccess(true);
    // }
    // if (showLoading === "Resend otp success") {
    //   setOpenResendOTP(false);
    //   setOpenSendOTP(true);
    // }
    // if (showLoading === "Reset password success") {
    //   setForgotPass(false);
    //   setResetPass(true);
    // }
    // if (showLoading === "Resend otp failed") {
    //   setOpenResendOTP(false);
    //   setOpenSignUp(true);
    // }
  }, [showLoading]);
  useEffect(() => {
    if (!openSendOTP) {
      setInputValues({
        input1: "",
        input2: "",
        input3: "",
        input4: "",

        // Add more input values here
      });
    }
  }, [openSendOTP]);
  useEffect(() => {
    setToken(getToken());
    if (valueSearch !== "") {
    }
  }, []);

  useEffect(() => {
    if (valueSearch === "") {
      setShopAndHostFiltered([]);
    } else {
      setShopAndHostFiltered(arrNew());
    }
  }, [arrNew().length]);

  useEffect(() => {
    if (!listHost.length) {
      dispatch(getUserOfShop({ type: "get_all" }));
    }
  }, [dispatch, listHost.length]);
  useEffect(() => {
    if (!listShop.length) {
      dispatch(getListShop());
    }
  }, [dispatch, listShop.length]);

  return (
    <div
      className={`${
        pathName === "/livestream" && width <= 800
          ? "bg-none absolute shadow-none w-full"
          : "bg-[#FFFFFF] sm:shadow-[0px_1px_2px_0px_rgba(0,0,0,0.2)]"
      } h-[64px] sm:h-[46px] sm:px-[16px] px-[20px] flex items-center justify-between z-100`}
      style={{ zIndex: 100 }}
    >
      <div className={`${openSearch && "hidden"} flex items-center`}>
        <span
          className={`${
            pathName === "/livestream" && width <= 800 && "icon_live_color z-10"
          } mr-[15px] sm:mr-[12px ] cursor-pointer z-100`}
          onClick={() => setHideSidebar((prev) => !prev)}
        >
          {width > 800 ? cts.menuIcon : cts.menuRespon}
        </span>
        <img
          className={`${
            pathName === "/livestream" && width <= 800 && "hidden"
          } cursor-pointer md:h-[32px]`}
          onClick={() => navigate("/")}
          src={width > 800 ? liveIcon : liveIconRes}
        />
      </div>
      <Input
        type="text"
        className="w-[35%] max-800px:hidden h-[40px] rounded-[12px]"
        suffix={<span>{cts.searchIcon}</span>}
        placeholder={i18n.t("SEARCH")}
        autoComplete="off"
        onChange={(e) => onSearchDebounceChange(e.target.value)}
      />

      <div className={`${openSearch && "hidden"} flex items-center card_noti`}>
        {width <= 800 && (
          <Button
            onClick={() => setOpenSearch(true)}
            className={`${
              pathName === "/livestream" && "hidden"
            } btn-search-home border-none shadow-none p-0`}
          >
            {cts.searchIcon}
          </Button>
        )}
        {userInfo?._id && (
          <>
            <Link
              onClick={() => localStorage.removeItem("buyCoin")}
              to="/purchase-point"
              className={`${
                pathName === "/livestream" && width <= 800 && "hidden"
              } mr-[16px] cursor-pointer sm:mx-[12px] flex items-center sm:border-none h-[40px] border border-[#EF6820] rounded-[8px] max-800px:ml-[10px] px-[12px] sm:px-[0px]`}
            >
              <span className="mr-[8px] sm:mr-[0px] btn_icon">{cts.coinIcon1}</span>
              <span className="text-[#EF6820] sm:hidden text-[13px] notosans_medium">
                {i18n.t("PURCHASE_POINT")}
              </span>
            </Link>
            <div className="flex items-center bg-[#FEF6EE] rounded-[8px] h-[40px] px-[16px] mr-[16px]">
              <span className="text-[#344054] text-[16px] notosans_normal max-960px:hidden">
                {i18n.t("POINT")} :
              </span>
              <span className="ml-[8px] text-[#344054] text-[16px] notosans_bold mr-[2px]">
                {formatMoney(Number(pointCurren)) || 0}
              </span>
              <span> {cts.coinIcon1}</span>
            </div>
            <Dropdown
              className={`${pathName === "/livestream" && width <= 800 && "hidden"} drd_info_res`}
              menu={{ items }}
              trigger={["click"]}
            >
              <a className="cursor-pointer" onClick={(e) => e.preventDefault()}>
                <img
                  className="rounded-[50%] h-[40px] w-[40px] sm:h-[24px] sm:w-[24px] object-cover"
                  alt="avatar"
                  src={
                    userInfo?.avatar
                      ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}`
                      : userInfo?.provider_pic
                      ? userInfo?.provider_pic
                      : noAva
                  }
                />
              </a>
            </Dropdown>
          </>
        )}
        {!userInfo?._id && __renderSignUpLogin()}
        {__renderModalLogin()}
        {__renderModalSignUp()}
        {__renderModalForgotPassword()}
        {__renderModalResetPassword()}
        {__renderModalResendOTP()}
        {__renderModalSendOTP()}
        <Modal
          onOk={() => setOpenConfirmSuccess(false)}
          onCancel={() => setOpenConfirmSuccess(false)}
          centered
          className="modal_sign_up"
          open={openConfirmSuccess}
        >
          <span>{cts.checkSuccess}</span>
          <span className="text-[16px] mb-[20px] notosans_normal mt-[12px]">
            アカウントの検証が完了しました。
          </span>
          <Button
            onClick={() => {
              setOpenConfirmSuccess(false);
              setOpenLogin(true);
            }}
            className="bg-[#EF6820] mt-[16px ] w-full hover:border-none hover:!text-white hover:bg-[#EF6820]/80 h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
          >
            {i18n.t("LOGIN")}
          </Button>
        </Modal>
        {/* ================================ */}
        {/* <Popconfirm
          placement="bottomRight"
          title={''}
          open={openPop}
          description={
            <div
              onBlur={(e) => {
                console.log('e', e);
                e.stopPropagation();
                setOpenPop(false);
              }}
              onClick={() => setOpenPop(false)}
              className="flex flex-col w-[250px]"
            >
              <div className="flex pb-[8px] items-center border-[#EAECF0] border-b mx-[16px] mt-[12px]">
                <img className="rounded-[20px] h-[40px] w-[40px]" alt="avatar" src={Avatar} />
                <div className="flex flex-col ml-[12px]">
                  <span className="text-[14px] text-[#344054] notosans_bold cursor-pointer">
                    HIKARI
                  </span>
                  <span className="text-[14px] text-[#475467] notosans_normal cursor-pointer">
                    hikari2023@gmail.com
                  </span>
                </div>
              </div>
              <div className="flex flex-col pb-[8px] pt-[8px] border-[#EAECF0] border-b mx-[16px]">
                <div className="flex items-center">
                  <span>{cts.userAva}</span>
                  <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
                    {i18n.t('MY_ACCOUNT')}{' '}
                  </span>
                </div>
                <div className="flex items-center ">
                  <span>{cts.headIcon}</span>
                  <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
                    {i18n.t('HELP')}{' '}
                  </span>
                </div>
              </div>
              <div className="flex pt-[8px] items-center pb-[12px] mx-[16px]">
                <span>{cts.logOutIcon}</span>
                <span className="text-[14px] notosans_normal cursor-pointer text-[#101828]">
                  {i18n.t('LOG_OUT')}{' '}
                </span>
              </div>
            </div>
          }
          className="pop_acc"
        >
          <a onClick={() => setOpenPop((prev) => !prev)}>
            <img className="rounded-[20px] h-[40px] w-[40px]" alt="avatar" src={Avatar} />
          </a>
        </Popconfirm> */}
      </div>
      {/* PC ONLY */}
      {shopAndHostFiltered.length ? (
        <div
          className={`${
            valueSearch === "" && "hidden"
          } bg-white border border-[#D0D5DD] absolute mt-[250px] card_search_home z-10 h-[200px] stroke-[#D0D5DD] rounded-lg overflow-auto max-w-[35%] w-full  left-1/2 transform -translate-x-[48%]`}
        >
          {shopAndHostFiltered.map((x: any, index: number) => {
            return (
              <div
                onClick={() => handleNavigate(x)}
                className="h-[60px] cursor-pointer border-b py-2 border-rgba[(0,0,0,0.3)] flex items-center justify-between px-[16px]"
                key={index}
              >
                <div className="flex items-center gap-2">
                  <div
                    className={`${
                      channelsSt?.find((item: any) => item?.user?._id.includes(x?._id))
                        ? " border-[#F04438]"
                        : "border-[#D0D5DD]"
                    } border rounded-full`}
                  >
                    <img
                      src={generateAvatar(x?.avatar, x.type)}
                      className="h-[40px] w-[40px] rounded-full object-cover"
                    />
                  </div>
                  <span className="notosans_normal text-[14px] text-[#101828]">
                    {x.nickName || x.name}
                  </span>
                </div>
                <span
                  className={`${
                    !channelsSt?.find((item: any) => item?.user?._id.includes(x?._id)) && "hidden"
                  } text-[#F04438] text-[12px]`}
                >
                  LIVE
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={`${
            valueSearch === "" && "hidden"
          } bg-white absolute mt-[250px] card_search_home z-10 h-[200px] stroke-[#D0D5DD] rounded-[8px] overflow-auto max-w-[35%] left-1/2 transform -translate-x-[48%] w-full`}
        >
          <div className="flex items-center justify-center h-full">
            <Empty
              description={i18n.t("NO_DATA")}
              imageStyle={{
                height: 60,
              }}
            />
          </div>
        </div>
      )}

      {/* MOBILE ONLY */}
      {openSearch && (
        <>
          <div className="flex items-center w-full">
            <Button
              onClick={() => {
                setOpenSearch(false);
                setValueSearch("");
              }}
              className="border-none shadow-none p-0"
            >
              {cts.backIcon}
            </Button>
            <Input
              onChange={(e) => onSearchDebounceChange(e.target.value)}
              className="flex flex-1 ml-[12px] h-[38px] rounded-[8px]"
              suffix={<Button className="border-none shadow-none p-0">{cts.searchIcon}</Button>}
              placeholder={i18n.t("SEARCH")}
            />
          </div>
          <div
            className={`${
              valueSearch === "" && "hidden"
            } bg-white absolute mt-[250px] card_search_home z-10 h-[200px] stroke-[#D0D5DD] rounded-[8px] overflow-auto w-[92%]`}
          >
            {shopAndHostFiltered.map((x: any, index: number) => {
              return (
                <div
                  className="h-[50px] border-b border-rgba[(0,0,0,0.3)] flex items-center justify-between px-[16px]"
                  key={index}
                >
                  <div className="flex items-center">
                    <div
                      className={`${
                        !channelsSt?.find((item: any) => item?.user?._id.includes(x?._id)) &&
                        "hidden" &&
                        "border-[1.5px] border-[#F04438]"
                      } h-[35px] w-[35px] rounded-[50%] mr-[10px]`}
                    >
                      <img
                        src={generateAvatar(x?.avatar, x.type)}
                        className="h-[32px] w-[32px] rounded-[50%] object-cover"
                      />
                    </div>
                    <span className="notosans_normal text-[14px] text-[#101828]">
                      {x.nickName || x.name}
                    </span>
                  </div>
                  <span
                    className={`${
                      !channelsSt?.find((item: any) => item?.user?._id.includes(x?._id)) && "hidden"
                    } text-[#F04438] text-[12px]`}
                  >
                    LIVE
                  </span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default HeaderComponent;
