import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useAppSelector } from "app/hooks";
import noAva from "../../assets/images/noAva.png";
import noShop from "../../assets/images/noShop.png";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { URL_UPLOAD_IMAGE } from "../../constants";
import { generateAvatar, handleImageErrorWithType } from "helpers";
type Props = {};

const ListStore = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listShop = useAppSelector((state) => state.shop.listShop);
  return (
    <div className="flex justify-center pt-[10px] pb-[40px] mt-[2px] list_store max-500px:pt-3">
      <div className="flex-col flex w-full size_content_hd">
        <div className="">
          <BreadcrumbComponent breadMain={i18n.t("STORE_INFO")} />
        </div>
        <span className="text-[#101828] mt-[10px] text-[24px] notosans_bold max-500px:text-lg max-500px:mt-0">
          {i18n.t("STORE_INFO")}
        </span>
        <div className="grid grid-cols-3 max-1080px:grid-cols-2 gap-4 mt-[20px] mb-[20px] max-500px:flex max-500px:flex-col max-500px:mt-4">
          {listShop
            ?.filter((i: any) => i?.status === "active")
            ?.map((x: any, y: any) => {
              return (
                <div
                  key={x?._id}
                  className="bg-white border border-[#EAECF0] rounded-[12px] flex flex-col"
                >
                  <div className="p-[16px] flex flex-col min-h-[207px]">
                    <div className="flex items-center justify-between">
                      <span className="text-[#101828] text-[16px] notosans_bold w-[70%]">
                        {x?.name}
                      </span>
                      <img
                        src={
                          !x.avatar || x.avatar === "null"
                            ? noShop
                            : `${URL_UPLOAD_IMAGE}/shops/${x?.avatar}`
                        }
                        className="h-[60px] w-[60px] rounded-[50%] object-cover"
                      />
                    </div>
                    <div className="flex items-center mt-[8px]">
                      <span className="h-[16px] w-[16px] mr-[8px]">{cts.location1Icon}</span>
                      <span className="text-[#667085] text_1_line text-[14px] notosans_normal">
                        {x?.address}
                      </span>
                    </div>
                    <span className="text-[#667085] text_1_line text-[14px] notosans_normal ml-[24px]">
                      {/* {x.nameBuilding} */}
                    </span>
                    <div className="flex items-center mt-[8px]">
                      <span className="h-[16px] w-[16px] mr-[8px]">{cts.phoneIcon}</span>
                      <span className="text-[#667085] text-[14px] notosans_normal">{x?.phone}</span>
                    </div>
                    <div className="flex items-center mt-[8px]">
                      <span className="h-[16px] w-[16px] mr-[8px]">{cts.memberIcon}</span>
                      <span className="text-[#667085] text-[14px] notosans_normal">
                        {x?.userOfShops.length || 0} {i18n.t("LIVER")}
                      </span>
                    </div>
                    <div className="flex items-center ml-[24px] mt-[4px] h-[24px]">
                      {x?.userOfShops
                        .filter((it: any, idx: any) => idx <= 4)
                        .map((item: any) => {
                          return (
                            <div className="flex items-center">
                              <img
                                src={generateAvatar(item?.avatar, "streamer")}
                                className="h-[24px] w-[24px] rounded-lg mr-[4px] object-cover"
                                onError={(e: any) => handleImageErrorWithType(e, "streamer")}
                              />
                            </div>
                          );
                        })}
                      {x?.userOfShops.length > 5 && (
                        <div className="h-[24px] w-[24px] bg-[#F0F0F0] rounded-lg flex items-center justify-center">
                          <span className="text-[12px] text-[#696969] notosans_normal">
                            +{x?.userOfShops.length - 5}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate({
                        pathname: "/detail-store",
                        search: createSearchParams({
                          key_store: `${x?._id}`,
                        }).toString(),
                      });
                    }}
                    className="py-[8px] flex items-center border-t border-[#EAECF0] cursor-pointer justify-center"
                  >
                    <span className="text-[#EF6820] mr-[10px] text-[13px] notosans_medium">
                      {i18n.t("SEE_MORE")}
                    </span>
                    <span>{cts.rightColor}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ListStore;
