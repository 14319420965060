import i18n from "locales/i18n";
import * as cts from "../../../constants/ui/svg";
import ava1 from "../../../assets/images/EventSquare-01.png";
import noAva from "../../../assets/images/noAva.png";
import { Empty } from "antd";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { getEventLive } from "slices/eventLiveSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import { createSearchParams, useNavigate } from "react-router-dom";
import { URL_UPLOAD_IMAGE } from "../../../constants";
const Recommended = (props: any) => {
  const { width, height, channelsSt } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listEventLive = useAppSelector((state) => state.eventLive.listEvent);
  // const channelsSt = useAppSelector((state) => state.channels.channels);

  // useEffect(() => {
  //   dispatch(getEventLive({ type: "get_all", page: 1, perPage: 8 }));
  // }, []);

  return (
    <div className=" recomment_bg max-500px:pt-[25px] pt-[45px] flex justify-center items-center mt-[50px] max-500px:mt-[24px]">
      <div className="size_content_hd flex w-full flex-col items-start">
        <div
          className={`${
            channelsSt?.length < 1 ? "" : "pb-[20px]"
          }relative flex items-center justify-center `}
        >
          <span className="text-[#FFFFFF] max-500px:mb-[6px] max-500px:text-[16px] text-[20px] notosans_bold absolute">
            {i18n.t("RECOMMENT_TODAY")}
          </span>
          <span className="icon_size_52">{cts.boxTitle}</span>
        </div>
        {/* <div className="flex items-center justify-center notosans_thin pb-[100px] w-full">
          {i18n.t("NO_DATA")}
        </div> */}
        <Swiper
          slidesPerView={
            width > 1400 ? 6 : width >= 1080 ? 5 : width >= 800 ? 4 : width >= 650 ? 3 : 2
          }
          spaceBetween={15}
          loop={channelsSt.length > 2}
          navigation={width > 500 ? true : false}
          modules={[Pagination, Navigation]}
          className={`${channelsSt?.length < 1 ? "" : "pb-[40px]"} w-full  swiper_profile swiper1`}
        >
          {channelsSt
            ?.filter((i: any) => i?.user?.enableLive !== false)
            ?.map((x: any, y: any) => {
              return (
                <SwiperSlide
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate({
                      pathname: "/livestream",
                      search: createSearchParams({
                        key: `${x?.user?._id}`,
                      }).toString(),
                    });
                  }}
                  key={y}
                  className="flex flex-col max-500px:h-[40vw] max-500px:!w-[40vw] cursor-pointer max-500px:mb-[40px]"
                >
                  <div className="border-dotted border-[1px] border-[#667085] w-full"></div>
                  <div className="flex items-center justify-between py-[8px]">
                    <span className=" text-[#667085] text-[14px] notosans_bold">
                      {moment(x?.updatedAt).format("YYYY-MM-DD")}
                    </span>
                    <span className=" text-[#667085] text-[14px] notosans_bold">
                      {moment(x?.updatedAt).format("hh:mm")}
                    </span>
                  </div>
                  <div className=" flex items-center justify-center detail_vid hover:shadow-[0px_4px_8px_-2px_rgba(0,0,0,0.4)] rounded-[8px]">
                    <div className="relative flex justify-center items-end rounded-[8px] w-full ">
                      <div className="absolute h-[30%] flex items-end bg_linear">
                        {/* <span className=" text-white text-[14px] notosans_bold text_1_line ml-[8px] mb-[8px]">
                        {x?.title}
                      </span> */}
                      </div>
                      <img
                        className="rounded-[8px] max-500px:h-[40vw] max-500px:!w-[40vw] h-[180px] w-full object-cover"
                        src={
                          x?.user?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${x?.user?.avatar}` : noAva
                        }
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        {channelsSt?.length < 1 && (
          <div className="flex justify-center items-center w-full pb-[20px]">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(Recommended);
