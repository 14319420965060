/* eslint-disable no-unused-vars */
import { message } from "antd";
import React from "react";
import ReactDOM from 'react-dom';
// import * as errorCts from "../constants/ui/error";

const success = (content: string, duration = 3000) => {
  message.config({
    top: 30,
    duration: duration / 1000,
  });
  message.success(content);
};

const danger = (content: string, duration = 3000) => {
  message.config({
    top: 30,
    duration: duration / 1000,
  });
  message.error(content);
};

const warning = (content: string, duration = 3000) => {
  message.config({
    top: 30,
    duration: duration / 1000,
  });
  message.warning(content);
};

export const toast = (message: string, type: string, time = 3000) => {
  if (type === "success") {
    return success(message, time);
  }

  if (type === "warning") {
    return warning(message, time);
  }
  return danger(message, time);
};
export const warningToast = (message: string) => {
  warning(message, 3000);
};
export const errorToast = (message: string) => {
  danger(message, 3000);
};

export const successToast = (message: string, time = 3000) => {
  success(message, time);
};

export const failedToast = (error: { message: string }, title: string) => {
  if (error && error.message === "Network Error") {
    // danger(errorCts.NETWORK_DISCONNECTED);
  } else {
    danger(title);
  }
};
export const addComponent = (Modal: any, props: any) => {
  const modalContainerOld = document.getElementById('modal-container');
  if (modalContainerOld) {
    modalContainerOld.remove();
  }
  const modalContainer = document.createElement('div');
  modalContainer.classList.add('modal-container');
  modalContainer.id = 'modal-container';
  document.body.appendChild(modalContainer);

  const onClose = () => {
    modalContainer.remove();
    turnOnScrollBody();
  };

  const ModalElement = React.createElement(Modal, {
    ...props,
    onClose
  });

  ReactDOM.render(
    ModalElement,
    modalContainer
  );
  turnOffScrollBody();
};

export const turnOffScrollBody = () => {
  const styleElement = document.createElement("style");
  styleElement.setAttribute("id", "disable-scroll-style");
  styleElement.innerHTML = `
          body {
              overflow: hidden;
          }
      `;
  document.head.appendChild(styleElement);
};

export const turnOnScrollBody = () => {
  const styleElement = document.getElementById("disable-scroll-style");
  if (styleElement) {
    document.head.removeChild(styleElement);
  }
};
