import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  chats: <any>[],
  userInfo: <any>{},
  arrRankGift: <any>[]
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    fetchChats(state, action) { },
    fetchChatsSuccess(state, action) {
      state.chats = action.payload;
      state.arrRankGift = action.payload?.filter((x: any) => x?.coins > 0)
        ?.reduce((acc: any, item: any) => {
          const existingItemIndex = acc.findIndex((i: any) => item?.sender?._id === i?.sender?._id);

          if (existingItemIndex !== -1) {
            acc[existingItemIndex] = {
              ...acc[existingItemIndex],
              coins: acc[existingItemIndex].coins + item?.coins,
            };
          } else {
            acc.push({ ...item, coins: item?.coins });
          }

          return acc;
        }, [])
    },

    createChatSuccess(state, action) {
      if (action.payload?.localData) {
        state.userInfo = action.payload?.sender
        state.chats = [...state.chats, action.payload];
      }

      if (state.userInfo?._id !== action.payload?.sender?._id) {
        state.chats = [...state.chats, action.payload];
      }

    },
  },
});

// Action
export const { fetchChats, fetchChatsSuccess, createChatSuccess } = chatsSlice.actions;

// Reducer
const historyPlanReducer = chatsSlice.reducer;

export default historyPlanReducer;
