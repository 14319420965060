import * as cts from "../../constants/ui/svg";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const CommercialTradingLawPage = () => {
  const navigate = useNavigate();

  const arr = [
    {
      title: "事業者の名称",
      content: ["株式会社Exceed Plus"],
    },
    {
      title: "代表者または運営責任者の氏名",
      content: ["北島　仁"],
    },
    {
      title: "住所",
      content: ["〒450-0002", "愛知県名古屋市中村区名駅4丁目24-5 第2森ビル 401"],
    },
    {
      title: "電話番号",
      content: ["052-990-6180", "※ お問い合わせは基本的にメールでお願いいたします。"],
    },
    {
      title: "メールアドレス",
      content: ["info@host-live.net"],
    },
    {
      title: "販売価格",
      content: ["各コンテンツまたはサービスごとに表示された価格に準じます。"],
    },
    {
      title: "商品代金以外の必要料金",
      content: ["インターネット接続にかかる通信料はお客様の負担となります。"],
    },
    {
      title: "代金の支払方法",
      content: ["以下のいずれかの方法でお支払いいただけます。"],
    },
    {
      title: "代金の支払方法",
      content: [
        "以下のいずれかの方法でお支払いいただけます。",
        "・クレジットカード",
        "・決済電子マネー決済",
        "・その他当社が定める決済方法",
      ],
    },
    {
      title: "代金の支払時期",
      content: [
        "・クレジットカード決済の場合：各カード会社の定める引き落とし日",
        "・電子マネー決済の場合：即時決済",
      ],
    },
    {
      title: "商品の引渡時期",
      content: ["・デジタルコンテンツ・サービスについては、決済完了後即時利用可能。"],
    },
    {
      title: "返品・キャンセルについて",
      content: [
        "・デジタルコンテンツの性質上、返品・キャンセルは原則としてお受けできません。",
        "・サービスの利用に関するキャンセルポリシーは別途利用規約をご確認ください。",
      ],
    },
    {
      title: "動作環境",
      content: [
        "推奨環境は下記のとおりです。 下記以外の環境はサポートの対象外となりますので、推奨環境でのご利用をお願いいたします。",
      ],
    },
    {
      type: "device-requirement",
      title: "スマートフォン:アプリ版",
      content: [
        "必要環境",
        "■ iOS 15.0 以降 / iPadOS 15.0以降 Android 8.0 以降",
        "推奨環境",
        "■ iOS 16.0 以降 / iPadOS 16.0以降 Android 11.0 以降",
        "▼高画質で配信する場合の設定 iOSアプリ版　解像度: 480p　フレームレート: 30fps Androidアプリ版　解像度: 540p　フレームレート: 30fps",
      ],
    },
    {
      type: "device-requirement",
      title: "ラップトップ:PCブラウザ版",
      content: [
        "■【Windows】 Microsoft Edge 最新ver Google Chrome 最新ver",
        "■【Mac】 Safari 最新ver Google Chrome 最新ver",
      ],
    },
    {
      type: "device-requirement",
      title: "通信環境（配信時）",
      content: [
        "推奨環境",
        "■【Wi-Fi回線の場合】 ダウンロード速度：50（Mbps） アップロード速度：50～100（Mbps）",
        "■【モバイル回線の場合(3G、4Gなど)】 ダウンロード速度：40～50（Mbps） アップロード速度：10～50（Mbps）",
      ],
    },
    {
      type: "device-requirement",
      title: "通信環境（視聴・再生時）",
      content: [
        "推奨環境",
        "■ 低画質視聴時：1Mbps以上 オリジナル画質視聴時：2Mbps以上",
        "※お客様側の環境で推奨とされる通信速度の数値となります。 上記に満たない場合は低画質モードでの視聴を推奨します。",
      ],
    },
    {
      title: "免責事項",
      content: [
        "当社は、サービスの提供に関し、天災、システム障害その他不可抗力による利用不能について、一切の責任を負いません。",
      ],
    },
    {
      title: "お問い合わせ",
      content: ["お問い合わせは以下のメールアドレスよりお願いいたします。", "info@host-live.net"],
    },
  ];
  return (
    <div className="flex pt-[10px] justify-center pb-[40px] mt-[2px] pickup_video">
      <div className="size_content_hd w-full flex-col flex ">
        <div className="">
          <BreadcrumbComponent breadMain="特定商取引法に基づく表示" />
        </div>
        <div className="w-full flex flex-col items-center">
          <span className="text-[#101828] mt-[10px] text-[24px] notosans_bold max-500px:text-lg max-500px:font-bold">
            特定商取引法に基づく表示
          </span>
          <div className="mt-[20px] w-full rounded-[8px] border border-[#EF6820] p-[40px] bg-[#FEFAF5]">
            {arr.map((x, y) => {
              return (
                <div key={y} className="mb-[24px] flex flex-col">
                  {x.type !== "device-requirement" ? (
                    <>
                      <div className="flex items-center">
                        <span>{cts.squareOrangeIcon}</span>
                        <span className="ml-[8px] text-[16px] notosans_bold">{x.title}</span>
                      </div>
                      <div className="flex flex-col mt-[8px]">
                        {x.content.map((it) => {
                          return (
                            <span className="text-[14px] text-[#1D2939] notosans_normal">{it}</span>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center">
                        <span>{cts.blackDotIcon}</span>
                        <span className="ml-[8px] text-[14px] notosans_medium">{x.title}</span>
                      </div>
                      <div className="flex flex-col mt-[8px]">
                        {x.content.map((it) => {
                          return (
                            <span className="text-[14px] text-[#1D2939] notosans_normal">{it}</span>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
            <div className="flex w-full items-center justify-center">
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate({
                    pathname: "/",
                  });
                }}
                className="bg-white h-10 border border-[#EAECF0] rounded-[8px] flex items-center justify-center hover:!shadow-none hover:!border-[#EAECF0] px-4"
              >
                <span>{cts.iconBack}</span>
                <span className="text-[#EF6820] text-[14px] notosans_medium ml-2"> 戻る</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommercialTradingLawPage;
