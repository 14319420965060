import React, { useCallback, useState, useEffect, useRef } from "react";
import { Button, Input } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18n from "locales/i18n";
import * as cts from "../../../constants/ui/svg";
import { URL_UPLOAD_IMAGE, SOCKET_SERVER } from "../../../constants";
import { useAppDispatch, useAppSelector } from "app/hooks";
import noAva from "../../../assets/images/noAva.png";
import ava8 from "../../../assets/images/HOST IMAGE/pic01.jpg";
import { JWPlayer } from "../../../components/Common/JWPlayer";
import { createChatSuccess, fetchChats } from "slices/chatSlice";
import { getUserInfo } from "slices/userSlice";
import { changeFollow } from "slices/followSlice";
import { errorToast, formatMoney } from "helpers";
import { shallowEqual } from "react-redux";

type Props = {
  width: number;
  //   renderButtonFollow: any;
  changeName: any;
  arrGift: any;
  //   key: any;
  isShowChat: boolean;
  joined: any;
  messagesEndRef: any;
  liveStream: any;

  sendChatMessage: (valueChat: string) => void;

  openBox: boolean;
  setOpenBox: React.Dispatch<React.SetStateAction<boolean>>;
  // valueChat: any;
  // setValueChat: any;
  selectGift: any;
  setSelectGift: React.Dispatch<React.SetStateAction<any>>;
  clickHeart: any;
  setClickHeart: React.Dispatch<React.SetStateAction<any>>;
  handleHeartClick: () => void;
  // detailHost:any;
  // listFollow:any;
  // chats:any;
  // userInfo:any;
};

const ScreenMobileLive = (props: Props) => {
  const {
    width,
    // renderButtonFollow,
    changeName,
    arrGift,
    // key,
    isShowChat,
    joined,
    // messagesEndRef,
    // liveStream,
    sendChatMessage,
    openBox,
    setOpenBox,
    // valueChat,
    // setValueChat,
    selectGift,
    setSelectGift,
    clickHeart,
    setClickHeart,
    handleHeartClick,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchparams] = useSearchParams();
  const key = searchparams.get("key");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const pointCurrent: any = localStorage.getItem("getUserInfo") || 0;

  const detailHost = useAppSelector((state) => state.follow.detailHost, shallowEqual);
  const listFollow = useAppSelector((state) => state.follow.listFollowInStream, shallowEqual);
  const chats = useAppSelector((state) => state.chats.chats, shallowEqual);
  const userInfo = useAppSelector((state) => state.users.userInfo, shallowEqual);
  const liveStream = useAppSelector((state) => state.liveStream.liveStream, shallowEqual);

  const [isShowInfo, setIsShowInfo] = useState(true);
  const [valueChat, setValueChat] = useState<any>({});
  const __renderButtonFollow = () => {
    return (
      <Button
        onClick={() => {
          if (userInfo?._id && key) {
            dispatch(changeFollow({ streamer: key, follower: userInfo?._id }));
          } else {
            errorToast("Please login!");
          }
        }}
        className={`${
          !listFollow?.find((i: any) => i?.follower?._id.includes(userInfo?._id))?.follower?._id
            ? "bg-[#EF6820] text-white hover:!border-[#EF6820] hover:!text-white"
            : "bg-[#EAECF0] text-[#475467] hover:!text-[#475467] border-none"
        } px-[12px] hover:text-none text-[14px] notosans_medium rounded-[8px] !flex !items-center max-500px:w-[120px] max-500px:justify-center `}
      >
        {!listFollow?.find((i: any) => i?.follower?._id.includes(userInfo?._id))?.follower?._id ? (
          <span className=" !flex !items-center">
            <span className="mr-[5px]">{cts.heartIcon}</span>

            {i18n.t("FOLLOW")}
          </span>
        ) : (
          <>{i18n.t("FOLLOWED")}</>
        )}
      </Button>
    );
  };
  const __renderGift = (item: any) => {
    return (
      <div className="max-800px:w-fit max-800px:flex max-800px:items-center">
        <div className="bg-[#F38744] max-800px:bg-[#1018284D] max-800px:p-[5px] max-800px:w-full  max-800px:rounded-[25px] max-800px:items-center max-800px:mx-[0px] mx-[16px] mb-[12px] rounded-[10px] p-[12px] flex justify-between items-start">
          <div className="flex items-center">
            <img
              className="h-[36px] w-[36px] max-800px:h-[40px] max-800px:w-[40px] rounded-[50%] mr-[8px] object-cover"
              src={
                item?.sender?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${item?.sender?.avatar}` : noAva
              }
            />
            <div className="flex flex-col">
              <span className="text-white w-auto text-[14px] max-800px:text-[13px] max-800px:!notosans_bold notosans_normal">
                {item?.sender?.nickName}
              </span>
              <span className="flex items-center max-800px:hidden text-white text-[14px] max-800px:text-[13px] notosans_normal">
                {formatMoney(item?.coins)}
                {cts.coinIcon1}
              </span>
            </div>
          </div>
          <img
            className="h-[60px] max-800px:h-[45px] max-800px:w-[45px] w-[60px] rounded-[50%] object-contain"
            src={`${URL_UPLOAD_IMAGE}/gifts/${item?.message}`}
          />
        </div>
        <span className="flex items-center min-800px:hidden text-white text-[15px] ml-[5px] notosans_normal">
          {formatMoney(item?.coins)}
          {cts.coinIcon1}
        </span>
      </div>
    );
  };
  const __renderContent = () => {
    return (
      <>
        <div className="absolute z-10 h-[90px] bg-gradient-to-b pt-[46px] px-[16px] from-[#202020]/70 via-[#202020]/40 to-[#202020]/0 w-[100vw]">
          <div className="flex items-center">
            <Link
              to="/"
              className={`${
                width > 800 && "hidden"
              } flex items-center border-none hover:!border-none shadow-none hover:!shadow-none mr-[12px]`}
            >
              <span>{cts.backHome}</span>
              <span className="text-[#475467] text-[14px] notosans_medium ml-[8px]">
                {i18n.t("HOME")}
              </span>
            </Link>
            <div
              className={`${
                isShowInfo === false && "hidden"
              } bg-[#1018284D] flex-1 mr-[12px] px-[6px] rounded-[100px] h-[44px] flex justify-between items-center`}
            >
              <div className="flex items-center">
                <img
                  src={
                    detailHost?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}` : noAva
                  }
                  className="h-[36px] object-cover w-[36px] rounded-lg ml-2 mr-1"
                />
                <div className="flex flex-col">
                  <span className="text-[14px] notosans_medium text-white">
                    {detailHost?.nickName}
                  </span>
                  <div className="flex items-center">
                    <span className="icon_respon_sub_color">{cts.subIcon}</span>
                    <span className="text-[13px] notosans_normal ml-[4px] text-white">
                      {listFollow?.length}
                    </span>
                  </div>
                </div>
              </div>
              {__renderButtonFollow()}
            </div>
            <Button
              className={`${
                isShowInfo === false && "hidden"
              } p-0 border-none shadow-none flex items-center justify-center rounded-[50%] bg-[#1018284D] h-[44px] w-[44px] hover:bg-[#1018284D]`}
            >
              <span className="icon_respon_share_color">{cts.shareLinkIcon}</span>
            </Button>
          </div>
        </div>
        <div
          className={`${
            openBox ? "" : "px-[16px] pb-[12px]"
          } h-[45%] flex flex-col justify-end bg-gradient-to-t from-[#202020]/80 via-[#202020]/40 to-[#202020]/0 w-[100vw] absolute bottom-[0px] z-10`}
        >
          <div
            onClick={() => setIsShowInfo((prev) => !prev)}
            className={`${
              openBox && "hidden"
            } flex justify-end h-full relative flex-col box_chat overflow-y-auto pb-[8px] `}
          >
            <div className="overflow-auto box_chat_mobile">
              {chats
                ?.filter(
                  (x: any) =>
                    (x?.sender?._id === userInfo?._id || x?.sender?._id === key) &&
                    x?.status !== "blocked" &&
                    x?.message !== "true"
                )
                ?.map((x: any, y: any) => {
                  return (
                    <>
                      {x?.coins > 0 ? (
                        __renderGift(x)
                      ) : (
                        <div key={y} className="flex pb-[12px]">
                          <img
                            className="h-[32px] w-[32px] rounded-[50%] mr-[8px] object-cover"
                            src={
                              x?.sender?.avatar
                                ? `${URL_UPLOAD_IMAGE}/avatars/${x?.sender?.avatar}`
                                : noAva
                            }
                          />
                          <div className="flex flex-col">
                            {/* {formatMoney(x?.coin)} */}
                            <span
                              // onClick={() => setFollow((prev) => !prev)}
                              className={`${
                                x.coin ? "text-[#EF6820]" : "text-white"
                              } text-[13px] notosans_normal`}
                            >
                              {x?.sender?.nickName}
                            </span>
                            <span className="text-[13px] notosans_normal text-white">
                              {x?.message}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              <div ref={messagesEndRef} />

              {selectGift.coin !== 0 && selectGift.image !== "" && selectGift.push === true && (
                <div className="bg-[#101828]/30 rounded-[10px] px-[16px] py-[8px] flex justify-between items-center">
                  <div className="flex items-center">
                    <img
                      className="h-[32px] w-[32px] rounded-[50%] mr-[8px] object-cover"
                      src={
                        userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva
                      }
                    />
                    <div className="flex flex-col">
                      <span className="text-white text-[13px] notosans_bold">
                        {userInfo?.nickName || userInfo?.userName}
                      </span>
                      <span className="flex items-center text-white text-[13px] notosans_normal">
                        {selectGift.coin}
                        <span className="ml-[5px]">{cts.coinIcon1}</span>
                      </span>
                    </div>
                  </div>
                  <img
                    className="h-[32px] w-[32px] rounded-[50%] object-cover"
                    src={selectGift.image}
                  />
                </div>
              )}
            </div>
          </div>
          {openBox && (
            <>
              <div className="flex items-center justify-between bg-white pt-[12px] px-[12px]">
                <div className="flex items-center">
                  <span className="text-[#101828] text-[14px] notosans_bold mr-[12px]">
                    {i18n.t("SUPPORT")}
                  </span>
                  <img
                    className="h-[20px] w-[20px] rounded-[50%] mr-[8px] object-cover"
                    src={
                      userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva
                    }
                  />
                  <span className="text-[#101828] text-[14px] notosans_bold">
                    {changeName ? changeName : userInfo?.nickName}
                  </span>
                </div>
                <Button
                  onClick={() => {
                    setOpenBox(false);
                    setSelectGift({ id: "", coin: 0, image: "", push: true });
                  }}
                  className="border-none shadow-none p-0"
                >
                  {cts.close1Icon}
                </Button>
              </div>
              <div className="flex items-center bg-white pb-[12px] px-[12px]">
                <span className="text-[#101828] mr-[8px] text-[14px] notosans_normal">
                  {i18n.t("CURRENT_POINT")}
                </span>
                <span className="text-[#101828] text-[14px] notosans_bold mr-[2px]">
                  {formatMoney(Number(pointCurrent)) || 0}
                </span>
                <span>{cts.coinIcon1}</span>
                <div
                  className="bg-[#EF6820] px-[16px] flex items-center h-[26px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[12px]"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(
                      {
                        pathname: "/purchase-point",
                      },
                      {
                        state: {
                          prevPath: detailHost?.nickName,
                        },
                      }
                    );
                    localStorage.setItem("buyCoin", "buyCoin");
                  }}
                >
                  <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
                </div>
                {/* <Link
                      onClick={() => localStorage.setItem("buyCoin", "buyCoin")}
                      className="bg-[#EF6820] px-[16px] flex items-center h-[26px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[12px]"
                      to="/purchase-point"
                    >
                      <span className="text_1_line">{i18n.t("PURCHASE_POINT")}</span>
                    </Link> */}
              </div>
              <div className="h-full bg-white overflow-y-auto rank_scroll px-[12px] pb-[12px]">
                <div className="overflow-y-auto mt-[12px] grid grid-cols-4 gap-4">
                  {arrGift.map((x: any, y: number) => {
                    return (
                      <div
                        onClick={() => {
                          if (selectGift?.coin === x?.coin && selectGift?.image === x?.image) {
                            setSelectGift({ id: x?._id, push: false, coin: 0, image: "" });
                          } else {
                            setSelectGift({
                              id: x?._id,
                              push: false,
                              coin: x.coin,
                              image: x.image,
                            });
                          }
                        }}
                        key={y}
                        className={`${
                          selectGift?.image === x?.image && "bg-[#FEF6EE]"
                        } hover:bg-[#FEF6EE] h-[90px] cursor-pointer py-[8px] rounded-[8px] flex flex-col items-center justify-center`}
                      >
                        <img
                          src={`${URL_UPLOAD_IMAGE}/gifts/${x?.image}`}
                          className="w-[48px] h-[48px] object-contain"
                        />
                        <span className=" flex items-center">
                          {formatMoney(x.coin)} {cts.coinIcon1}
                        </span>
                        {/* <Button
                              // onClick={() => {
                              //   setSelectGift({ ...selectGift, push: true });
                              //   setOpenBox(false);
                              // }}
                              onClick={() => {
                                if (selectGift?.coin > 0 && selectGift?.image) {
                                  sendChatMessage();
                                  setArrNew([...arrNew, valueChat]);
                                  setValueChat({});
                                }
                              }}
                              className={`${
                                selectGift.image === x.image ? "block" : "hidden"
                              }  p-0 border-none rounded-t-none shadow-none text-[12px] w-full bg-[#EF6820] notosans_medium text-white h-[20px] flex items-center justify-center`}
                            >
                              {i18n.t("GIVE")}
                            </Button> */}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex justify-end p-[12px] bg-white ">
                <Button
                  disabled={selectGift?.coin > pointCurrent}
                  onClick={() => {
                    if (selectGift?.coin > 0 && selectGift?.image) {
                      sendChatMessage(valueChat.chat);
                      //   setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                      setOpenBox(false);
                    }
                  }}
                  className="bg-[#EF6820] mb-[20px] w-fit px-[20px] rounded-[8px] flex items-center justify-center h-[34px] text-white text-[14px] notosans_medium border-none hover:!text-white hover:!bg-[#EF6820]"
                >
                  {i18n.t("GIVE")}
                </Button>
              </div>
              {/* <Button className="bg-[#EF6820] w-fit px-[20px] rounded-[8px] flex items-center justify-center h-[34px] text-white text-[14px] notosans_medium border-none hover:!text-white hover:!bg-[#EF6820]">
                    {i18n.t("GIVE")}
                  </Button> */}
            </>
          )}

          {userInfo?._id && (
            <>
              {/* <Button
                        onClick={() => {
                          setClickHeart(true);
    
                          sendChatMessage();
                        }}
                        className="z-10 icon-heart w-[50px] focus:!ring-violet-300 hover:!border-[#F04438] p-0 absolute mt-[-60px] mr-[10px] btn-reaction drop-shadow-[0_2px_1.5px_#F04438] flex justify-center items-center rounded-[50%]
                         h-[50px]"
                      >
                        {cts.reactionLive}
                      </Button> */}
              {userInfo?._id && !openBox && (
                <div className="w-full flex items-start justify-end absolute mb-[90px] pr-[35px]">
                  <Button
                    // ref={heartBtnRef}
                    onClick={handleHeartClick}
                    className={`${
                      isShowChat === false && "hidden"
                    } icon-heart border-none focus:outline-none focus:border-none hover:!border-none p-0 absolute mt-[-60px] mr-[10px] btn-reaction flex justify-center items-center`}
                  >
                    {cts.reactionLive}
                  </Button>
                </div>
              )}
              <div className={`${openBox && "hidden"} flex items-center justify-between mb-[35px]`}>
                <Input
                  value={valueChat.chat}
                  onChange={(e) =>
                    setValueChat({
                      id: Math.random(),
                      ava: ava8,
                      name: userInfo.nickName,
                      chat: e.target.value,
                    })
                  }
                  onPressEnter={() => {
                    if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                      sendChatMessage(valueChat.chat);
                      //   setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                    }
                  }}
                  className="h-[40px] bg-[#F5F5F540] border-none inp_live_respon_cmt flex-1 mr-[10px] rounded-[20px]"
                  placeholder="メッセージを送信"
                />
                <Button
                  onClick={() => {
                    setOpenBox((prev: any) => !prev);
                    // if (emojiPicker) {
                    // //   setEmojiPicker(false);
                    // }
                  }}
                  className="border-none mr-[10px] h-[40px] w-[40px] rounded-[50%] bg-[#1018284D] flex items-center justify-center shadow-none p-0"
                >
                  {cts.giftBox}
                </Button>
                <Button
                  onClick={() => {
                    // setSelectGift({ ...selectGift, push: true });
                    // setOpenBox(false);
                    // setEmojiPicker(false);
                    if (
                      (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") ||
                      (selectGift?.coin > 0 && selectGift?.image)
                    ) {
                      sendChatMessage(valueChat.chat);
                      //   setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                    }
                  }}
                  className="h-[40px] cursor-pointer p-0 w-[40px] flex items-center justify-center rounded-[50%] border-none text-white text-[14px] notosans_medium bg-[#EF6820]"
                >
                  {cts.sendMess}
                </Button>
              </div>
            </>
          )}
          <div
            onClick={() => {
              setIsShowInfo((prev) => !prev);
            }}
            className=""
          >
            {clickHeart.map((heart: any, idx: any) => (
              <span
                key={heart.id}
                className="heart-anim absolute btn-reaction pb-[100px] mt-[-70px] ml-[300px]"
                style={
                  {
                    animationDelay: `${idx * 0.05}s`,
                    transform: `translate(0, 0)`,
                    "--x": `${heart.x}px`,
                    "--y": `${heart.y}px`,
                  } as React.CSSProperties
                }
              >
                {cts.reactionLive}
              </span>
            ))}
          </div>
        </div>
      </>
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats?.length]);
  useEffect(() => {
    if (key) {
      dispatch(fetchChats({ receiver: key }));
    }
  }, [key]);
  return (
    <div className="relative overflow-hidden h-screen w-full">
      {__renderContent()}
      <div
        className={`${
          width >= 500 && "justify-center items-center flex bg-black"
        } w-full h-screen z-100 `}
      >
        <div className={`${width >= 500 ? "!w-[400px]" : "w-full relative"} h-full`}>
          <div className={`${width <= 800 && " w-full h-full absolute z-1000"}`}></div>

          {key && liveStream.camera === "enable" && (
            <JWPlayer
              thumbnail={ava8}
              url={`https://live.host-live.net:1935/live/general/${key}/playlist.m3u8`}
              className="vertical-player"
            />
          )}
          {key && liveStream.camera === "disable" && (
            <img
              className="h-full w-full object-cover"
              src={detailHost.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${detailHost?.avatar}` : noAva}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScreenMobileLive;
